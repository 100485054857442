import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Typography, CircularProgress, Grid, Hidden } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {
  Add as AddIcon,
  Edit as EditIcon,
  KeyboardBackspace as KeyboardBackspaceIcon,
  Check as CheckIcon,
  Clear as ClearIcon,
  Send as SendIcon
} from '@material-ui/icons';
import { MODE_LIST, MODE_VIEW, MODE_TO_ORDER_CART, MODE_TO_ORDER_CHECKOUT } from '../../../../constants/offer';
import history from '../../../../helpers/history';
import { MonthFilter, YearFilter } from '../../../../components';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: { },
  row: {
    marginTop: theme.spacing(2)
  },
  innerRow: {
    display: 'flex'
  },
  buttonRow: {
    textAlign: 'right',
    paddingTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  title: {
    marginLeft: 10
  },
  button: {
    marginRight: theme.spacing(1)
  },
  progress: {
    marginRight: theme.spacing(2)
  },
}));

const OfferToolbar = props => {
  const { className, onNewOffer, onOfferToOrder, onConfirmCart, onSendCart, onBackToCart, onFiltersChange, onDownload, title, mode, processing, disabled, cart, ...rest } = props;

  const classes = useStyles();

  const [year, setYear] = useState(parseInt(moment().format("YYYY")));
  const [month, setMonth] = useState(moment().format("MM"));

  const setYearFilter = (year) => {
    setYear(year);
  }

  const setMonthFilter = (month) => {
    setMonth(month);
  }

  useEffect(() => {
    onFiltersChange(year, month);
  }, [year, month]);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
      <Grid container>
      <Grid item className={classes.innerRow} md={6} xs={12}>
        <AttachMoneyIcon />
        <Typography className={classes.title}
          variant="h4"
        >
          {title}
        </Typography>
      </Grid>
      {mode === MODE_LIST ?
        <Grid item className={classes.buttonRow} md={6} xs={12}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => onNewOffer()}
          >
            <Hidden smUp><AddIcon /></Hidden>
            <Hidden xsDown>Nueva cotización</Hidden>
          </Button>
        </Grid>
      : null}
      {mode === MODE_VIEW ?
        <Grid item className={classes.buttonRow} md={6} xs={12}>
          
          {cart.is_editable && <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={() => onNewOffer()}
          >
            <Hidden smUp><EditIcon /></Hidden>
            <Hidden xsDown>Editar cotización</Hidden>
          </Button>}

          {cart.is_orderable && 
          <Button
          color="secondary"
          variant="contained"
          className={classes.button}
          onClick={() => onOfferToOrder()}
          >
            Hacer pedido
          </Button>}

          {cart.is_open && 
          <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={() => onDownload()}
          >
            Imprimir en PDF
          </Button>}
                    
          <Button
            color="default"
            variant="contained"
            onClick={() => history.push("/offers")}
            >
              <Hidden smUp><KeyboardBackspaceIcon /></Hidden>
              <Hidden xsDown>Volver</Hidden>
            </Button>
          

        </Grid>
      : null}
      {mode === MODE_TO_ORDER_CART ?
      <Grid item className={classes.buttonRow} md={6} xs={12}>
        
        <Button
        color="primary"
        variant="contained"
        className={classes.button}
        onClick={() => onConfirmCart()}
        >
          <Hidden smUp><CheckIcon /></Hidden>
          <Hidden xsDown>Confirmar</Hidden>
        </Button>

        <Button
        color="default"
        variant="contained"
        onClick={() => history.push("/offers")}
        >
          <Hidden smUp><ClearIcon /></Hidden>
          <Hidden xsDown>Cancelar</Hidden>
        </Button>

      </Grid>
      : null}
      {mode === MODE_TO_ORDER_CHECKOUT ?
      <Grid item className={classes.buttonRow} md={6} xs={12}>
      {processing ? <CircularProgress size={30} className={classes.progress} /> :
        <Button
        color="primary"
        variant="contained"
        className={classes.button}
        disabled={disabled}
        onClick={() => onSendCart()}
        >
          <Hidden smUp><SendIcon /></Hidden>
          <Hidden xsDown>Guardar</Hidden>
        </Button>}
        <Button
        color="secondary"
        variant="contained"
        className={classes.button}
        onClick={() => onBackToCart()}
        >
          <Hidden smUp><KeyboardBackspaceIcon /></Hidden>
          <Hidden xsDown>Anterior</Hidden>
        </Button>
        <Button
        color="default"
        variant="contained"
        onClick={() => history.push("/offers")}
        >
          <Hidden smUp><ClearIcon /></Hidden>
          <Hidden xsDown>Cancelar</Hidden>
        </Button>
    </Grid>
      : null}
      </Grid>
      </div>
      {mode === MODE_LIST ?
      <div className={classes.row}>
        <MonthFilter month={month} setMonth={(month) => setMonthFilter(month)} />
        <YearFilter year={year} setYear={(year) => setYearFilter(year)} />
      </div> : null}
    </div>
  );
};

OfferToolbar.propTypes = {
  className: PropTypes.string
};

export default OfferToolbar;
