import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { OfferToolbar } from './components';
import { useParams } from "react-router-dom";
import { MODE_TO_ORDER_CART, MODE_TO_ORDER_CHECKOUT } from '../../constants/offer';
import { CartTable, CartEmpty, CartList, CartDetails, Snackbar, TableProgress } from '../../components';
import { getCartErrors } from '../../helpers/cart';
import { getById as apiGetOfferById } from '../../api/offer';
import { save as saveApi } from '../../api/checkout';
import { remove as removeCart } from '../../actions/cart';
import { logout } from '../../actions/authentication';
import { stock as apiStock } from '../../api/checkout';
import { TYPE_ORDER, STORAGE_SUCCESS } from 'constants/cart';
import { CheckoutWarning } from '../Checkout/components';
import history from '../../helpers/history';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  grid: {
    marginTop: 1
  }
}));

const OfferToOrder = (props) => {
    
    const classes = useStyles();

    const { id } = useParams();
    
    const [message, setMessage] = useState({ open: false, autoHideDuration: null, severity: "success", title: "", messages: [] });
    const [mode, setMode] = useState(MODE_TO_ORDER_CART);
    const [cart, setCart] = useState(null);
    const [details, setDetails] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [disabled, setDisabled] = useState(true);
    
    useEffect(() => {
      apiGetOfferById(id).then(response => {
        if (response.status === 200 && response.data.status) {
          // Delivery to order delivery
          response.data.data.items.forEach(item => {
            item.delivery = item.order_delivery;
          });
          setCart(response.data.data);
        }
        else
          onApiError(response);
      });
    }, []);

    const updateItem = () => {
        setCart({ ...cart });
    }

    const deleteItem = (item) => {
        let newCart = { ...cart };
        newCart.items = newCart.items.filter(currentItem => {
            return currentItem.id !== item.id;
        });
        setCart(newCart);
    }

    const confirmCart = () => {
        let errors = getCartErrors(cart.items, true);
        if (errors.length === 0) {

          // Valido stock
          apiStock(cart.type, cart).then(response => {
            if (response.status === 200 && response.data.status) {
              setMode(MODE_TO_ORDER_CHECKOUT);
            }
            else {
              onApiError(response);
            }
            
          });

        }
        else {
          setMessage({
            open: true,
            autoHideDuration: null,
            severity: "error",
            title: "Error",
            messages: errors });
        }
    }

    const sendCart = () => {

      const data = {
        ...cart,
        client_reference: details.clientReference,
        comments: details.comments,
        volume_discount: details.volumeDiscount,
        file: details.file
      };
  
      setProcessing(true);
      saveApi(TYPE_ORDER, data).then(response => {
        if (response.status === 200 && response.data.status) {
          
          // Show message
          localStorage.setItem(STORAGE_SUCCESS, response.data.message);
            
          // Go to offers
          history.push("/orders");
        }
        else
          onApiError(response);
        setProcessing(false);
      });

    }

    const backToCart = () => {
        setMode(MODE_TO_ORDER_CART);
    }
    
    const handleClose = () => {
      setMessage({ ...message, open: false });
    };

    const onApiError = (response) => {
      if (response.status === 401) {
        props.logout();
      }
      else {
        setMessage({
          open: true,
          autoHideDuration: null,
          severity: "error",
          title: "Error",
          messages: response.data.data ? response.data.data : [response.data.message] });
      }
    }

    const onDetailsChange = (values) => {
      setDetails(values);
      if (disabled)
        setDisabled(false);
    }

    return (
    <div className={classes.root}>
        <OfferToolbar
            title={"Pasar cotización a pedido"} mode={mode}
            onConfirmCart={() => confirmCart()}
            onSendCart={() => sendCart()}
            onBackToCart={() => backToCart()}
            onFiltersChange={(year, month) => { }}
            processing={processing}
            disabled={disabled}
        />
        <div className={classes.content}>
        {cart ? (
          mode === MODE_TO_ORDER_CART
        ?
            cart.items.length === 0 ?
            <CartEmpty message={"No se puede pasar a un pedido ya que no posee productos."} /> :
            <CartTable
              cart={cart}
              updateItem={(item) => updateItem()}
              deleteItem={(item) => deleteItem(item)} 
              />
        :
        <Fragment>
          <CheckoutWarning type={TYPE_ORDER} cart={cart} />
          <Grid container spacing={4} className={classes.grid} >
            <Grid item lg={4} md={6} xl={4} xs={12}>
                <CartDetails
                type={TYPE_ORDER}
                cart={cart}
                title={"Datos del pedido"}
                disabled={disabled}
                showReference={false}
                back={"/offers"}
                onApiError={(response) => onApiError(response)}
                onValuesChange={(values) => onDetailsChange(values)}
                />
            </Grid>
            <Grid item lg={8} md={6} xl={8} xs={12}>
                <CartList cart={cart} details={details} calculateTotals={true} />
            </Grid>
          </Grid>
        </Fragment>
        ) : <TableProgress />}
        </div>
        <Snackbar
            open={message.open}
            autoHideDuration={message.autoHideDuration}
            severity={message.severity}
            title={message.title}
            messages={message.messages}
            handleClose={() => handleClose()}
          />
    </div>
    );
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(removeCart());
      dispatch(logout());
    },
  };
}

export default connect(null, mapDispatchToProps)(OfferToOrder);