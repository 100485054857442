import { get, post } from './index';
import config from '../config/api.json';

export const login = (username, password) => {
    const data = {
        'grant_type': 'password',
        'username': username,
        'password': password,
        'client_id': config.clientId,
        'client_secret': config.clientSecret,
    };
    return post("oauth/token", data, false);
}

export const user = () => {
    return get("api/user");
}

export const password = (current, password, confirm) => {
    const data = {
        'current': current,
        'new': password,
        'confirm': confirm
    };
    return post("api/password", data);
}

export const account = () => {
    return get("api/user/account");
}