import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers';
import { loadState, saveState } from './localStorage';
import throttle from 'lodash.throttle';

const persistedState = loadState();

const store = createStore(
  rootReducer,
  persistedState,
  compose(applyMiddleware(thunkMiddleware)),
);

store.subscribe(throttle(() => {
  saveState({
    cart: store.getState().cart
  });
}, 1000));

export default store;