import { get, download as apiDownload } from './index';

export const search = (year, month) => {
    return get("api/offer/" + year + "/" + month);
}

export const getById = (id) => {
    return get("api/offer/" + id);
}

export const download = (id) => {
  return apiDownload("api/offer/download/" + id + "?ts=" + Date.now());
}