import { MY_CATALOG, AGRO_POWER } from '../constants/catalog';

const dictionary = {
    [MY_CATALOG]: {
        'title': 'Mi catálogo',
        'line': 'Línea',
        'machine': 'Máquina',
        'part': 'Parte',
        'line_or_code_required': 'Debe seleccionar línea o ingresar el código.',
    },
    [AGRO_POWER]: {
        'title': 'Agro Power',
        'line': 'Marca',
        'machine': 'Modelo',
        'part': 'Parte',
        'line_or_code_required': 'Debe seleccionar marca o ingresar el código.',
    }
}

export const getDictionary = (type, key) => {
    return dictionary[type][key];
}