import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent } from '@material-ui/core';
import moment from 'moment';

import { HomeToolbar } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  media: {
    textAlign: "center",
    paddingTop: 25
  }
}));

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HomeToolbar />
      <Card>
        <CardContent className={classes.media}>
          <img alt="" src={"https://www.uffenheimer.com.ar/data/upload/Home/Uffenb2b.jpg?ts=" + moment.now().toString()} />
        </CardContent>
      </Card>
    </div>
  );
};

export default Home;
