import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Button, CircularProgress, Hidden, Grid } from '@material-ui/core';
import {
  KeyboardBackspace as KeyboardBackspaceIcon,
  Check as CheckIcon,
  Send as SendIcon
} from '@material-ui/icons';
import history from '../../../../helpers/history';
import { getDictionary } from '../../../../helpers/cart';

const useStyles = makeStyles(theme => ({
  root: { },
  row: {
    marginTop: theme.spacing(2)
  },
  innerRow: {
    display: 'flex'
  },
  buttonRow: {
    textAlign: 'right',
    paddingTop: theme.spacing(1)
  },
  title: {
    marginLeft: 10
  },
  button: {
    marginRight: theme.spacing(1)
  },
  progress: {
    marginRight: theme.spacing(2)
  },
}));

const CheckoutToolbar = props => {
  const { className, onSend, processing, disabled, ...rest } = props;

  const classes = useStyles();

  const titleText = getDictionary(props.cart.type, 'confirm_title');

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
      <Grid container>
      <Grid item className={classes.innerRow} md={6} xs={12}>
      <CheckIcon />
      <Typography className={classes.title}
        variant="h4"
      >
        {titleText}
      </Typography>
      </Grid>
      <Grid item className={classes.buttonRow} md={6} xs={12}>
        
        <Button
        color="default"
        variant="contained"
        className={classes.button}
        onClick={() => history.push("/cart")}
        >
        <Hidden smUp><KeyboardBackspaceIcon /></Hidden>
        <Hidden xsDown>Volver</Hidden>
      </Button>
      {processing ? <CircularProgress size={30} className={classes.progress} /> :
        <Button
          color="primary"
          variant="contained"
          disabled={disabled}
          onClick={() => onSend()}
        >
          <Hidden smUp><SendIcon /></Hidden>
          <Hidden xsDown>Guardar</Hidden>
        </Button>}
        </Grid>
        </Grid>
      </div>
    </div>
  );
};

CheckoutToolbar.propTypes = {
  className: PropTypes.string
};

export default CheckoutToolbar;
