import React, { useState } from 'react';
import { Snackbar } from '../../components';
import { STORAGE_SUCCESS } from '../../constants/cart';

const SuccessSnackbar = () => {
  
    const success = localStorage.getItem(STORAGE_SUCCESS);
    if (success)
        localStorage.removeItem(STORAGE_SUCCESS);

  const [message, setMessage] = useState({ open: success ? true : false, autoHideDuration: null, severity: "success", title: "Operación exitosa", messages: [success] });

  const handleClose = () => {
    setMessage({ ...message, open: false });
  };

  return (
    <Snackbar
        open={message.open}
        autoHideDuration={message.autoHideDuration}
        severity={message.severity}
        title={message.title}
        messages={message.messages}
        handleClose={() => handleClose()}
    />
  );
};

export default SuccessSnackbar;