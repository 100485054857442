import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MuiAlertTitle from '@material-ui/lab/AlertTitle';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  text: {
      color: "#ffffff"
  }
}));

export default function CustomSnackbar(props) {
  const classes = useStyles();
  
  const handleClose = () => {
    props.handleClose();
  };
  return (
    <div className={classes.root}>
      <Snackbar open={props.open} autoHideDuration={props.autoHideDuration} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={handleClose}>
        <Alert severity={props.severity} onClose={handleClose}>
            <MuiAlertTitle className={classes.text}>{props.title}</MuiAlertTitle>
            {props.messages.map((message, index) => (
              <Typography key={index} className={classes.text}>{message}</Typography>
            ))}
        </Alert>
      </Snackbar>
    </div>
  );
}