import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { CheckoutToolbar, CheckoutWarning } from './components';
import { CartList, CartDetails, Snackbar } from '../../components';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { getCartErrors, getDictionary } from '../../helpers/cart';
import { save as saveApi } from '../../api/checkout';
import history from '../../helpers/history';
import { STORAGE_SUCCESS } from '../../constants/cart';
import { logout } from '../../actions/authentication';
import { remove as removeCart } from '../../actions/cart';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  grid: {
    marginTop: 1
  }
}));

const Checkout = (props) => {
  const classes = useStyles();

  const [message, setMessage] = useState({ open: false, autoHideDuration: null, severity: "success", title: "", messages: [] });
  const [details, setDetails] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleClose = () => {
    setMessage({ ...message, open: false });
  };

  const onApiError = (response) => {
    if (response.status === 401) {
      props.logout();
    }
    else {
      setMessage({
        open: true,
        autoHideDuration: null,
        severity: "error",
        title: "Error",
        messages: response.data.data ? response.data.data : [response.data.message] });
    }
  }

  const onSend = () => {
  
    const data = {
      ...props.cart,
      client_reference: details.clientReference,
      comments: details.comments,
      volume_discount: details.volumeDiscount,
      file: details.file
    };

    setProcessing(true);
    saveApi(props.cart.type, data).then(response => {
      if (response.status === 200 && response.data.status) {
        
        // Show message
        localStorage.setItem(STORAGE_SUCCESS, response.data.message);
        // Clear cart
        props.removeCart();

        // Go to offers
        history.push("/" + props.cart.type + "s");
      }
      else
        onApiError(response);
      setProcessing(false);
    });

  }

  const onDetailsChange = (values) => {
    setDetails(values);
    if (disabled)
      setDisabled(false);
  }

  if (props.cart && props.cart.items.length > 0) {

    const errors = getCartErrors(props.cart.items);

    if (errors.length === 0) {
      
      const title = getDictionary(props.cart.type, 'confirm_subtitle');
      
      return (
        <div className={classes.root}>
          <CheckoutToolbar cart={props.cart} onSend={() => onSend()} processing={processing} disabled={disabled} />
          <CheckoutWarning type={props.cart.type} cart={props.cart} />
          <Grid container spacing={4} className={classes.grid} >
            <Grid item lg={4} md={6} xl={4} xs={12}>
              <CartDetails
                type={props.cart.type}
                cart={props.cart}
                title={title}
                disabled={disabled}
                showReference={false}
                back={"/cart"}
                onApiError={(response) => onApiError(response)}
                onValuesChange={(values) => onDetailsChange(values)}
              />
            </Grid>
            <Grid item lg={8} md={6} xl={8} xs={12}>
              <CartList cart={props.cart} details={details} calculateTotals={true} />
            </Grid>
          </Grid>
          <Snackbar
            open={message.open}
            autoHideDuration={message.autoHideDuration}
            severity={message.severity}
            title={message.title}
            messages={message.messages}
            handleClose={() => handleClose()}
          />
        </div>
      );
    }
    else
      return <Redirect to={"/cart"} />;
    
  }
  return <Redirect to={"/products"} />;
};

const mapStateToProps = state => ({
  cart: state.cart
});

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(removeCart());
      dispatch(logout());
    },
    removeCart: () => dispatch(removeCart())
}}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);