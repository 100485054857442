import { CART_UPDATE } from '../constants/cart';
  
const initialState = null;

export default function cart(state = initialState, action) {
    switch (action.type) {
        case CART_UPDATE:
            return action.payload;
        default:
            return state;
    }
}