import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { Button, Card, CardContent, Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ProductsToolbar, ProductCard, ProductsTable } from './components';
import { Snackbar } from '../../components';
import { addItem, remove as removeCart } from '../../actions/cart';
import { logout } from '../../actions/authentication';
import { getCartErrors, getCartDuplicatesErrors } from '../../helpers/cart';
import { search as apiSearch } from '../../api/pruduct';
import { stock as apiStock } from '../../api/checkout';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

const ProductList = (props) => {
  const classes = useStyles();

  const [message, setMessage] = useState({ open: false, autoHideDuration: null, severity: "success", title: "", messages: [] });

  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [openSearchConfirm, setOpenSearchConfirm] = useState(false);
  const [madeSearch, setMadeSearch] = useState(false);
  const [searching, setSearching] = useState(false);
  
  const [filters, setFilters] = useState({
    glmId: null,
    lineId: null,
    groupId: null,
    description: null
  });

  const handleClose = () => {
    setMessage({ ...message, open: false });
  };

  const handleSelectProducts = (products) => {
    setSelectedProducts(products);
  };
  
  const handleAddProducts = (selectedProducts, clearSelectedProducts) => {

    const productsToAdd = products.filter(product => {
      return selectedProducts.indexOf(product.id) !== -1;
    });

    let errors = getCartErrors(productsToAdd);

    if (errors.length === 0)
      errors = getCartDuplicatesErrors(props.cart, productsToAdd);

    if (errors.length === 0) {

      // Valido stock
      apiStock(props.cart.type, { items: productsToAdd }).then(response => {
        if (response.status === 200) {

          if (response.data.status) {

            productsToAdd.map(product => {
              props.addItem(props.cart, {...product});
            });
      
            setMessage({ open: true, autoHideDuration: 5000, severity: "success", title: "Agregar productos", messages: [productsToAdd.length === 1 ? "Se ha agregado el producto correctamente!" : "Se han agregado los productos correctamente!"] });

            // Clear
            clearProducts(productsToAdd);
            if (clearSelectedProducts)
              setSelectedProducts([]);
          }
          else {
            setMessage({ open: true, autoHideDuration: null, severity: "error", title: productsToAdd.length === 1 ? "Error al agregar producto" : "Error al agregar productos", messages: response.data.data });
          }
          
        }
        else {
          onApiError(response);
        }
        
      });
      
    }
    else {
      setMessage({
        open: true,
        autoHideDuration: null,
        severity: "error",
        title: productsToAdd.length === 1 ? "Error al agregar producto" : "Error al agregar productos",
        messages: errors });
    }
  }

  const clearProducts = (productsToClear) => {
    let newSelectedProducts = [...selectedProducts];

    productsToClear.map(productToClear => {
      
      let product = products.find(product => { return product.id === productToClear.id; });
      product.qty = 0;
      product.unit = product.units[0];

      newSelectedProducts = newSelectedProducts.filter(id => id !== productToClear.id);
    });
    updateProducts(products);

    if (newSelectedProducts.length !== selectedProducts.length)
      handleSelectProducts(newSelectedProducts);
  }

  const updateProducts = (products) => {
    setProducts([...products]);
  };

  useEffect(() => {
    if (selectedProducts.length > 0) {
      setOpenSearchConfirm(true);
    }
    else if (filters.glmId) {
      handleSearchConfirm(true);
    }
  }, [filters]);

  const handleSearch = (glmId, lineId, groupId, description) => {
    if (!glmId) {
      setMessage({
        open: true,
        autoHideDuration: null,
        severity: "error",
        title: "Error al buscar productos",
        messages: ["Debe seleccionar GLM."] });
      return;
    }

    setFilters({
      glmId: glmId,
      lineId: lineId,
      groupId: groupId,
      description: description
    });

  }

  const handleSearchConfirm = (confirm) => {

    setOpenSearchConfirm(false);
    
    if (confirm) {
      
      //console.log(filters);
      setSearching(true);

      // Search
      apiSearch(filters.glmId, filters.lineId, filters.groupId, filters.description, props.onSale).then(response => {
        if (response.status === 200 && response.data.status) {
          
          // Clear selected products
          setSelectedProducts([]);
          
          // Map products
          const products = response.data.data;
          setPage(0);
          setProducts(products);
          setMadeSearch(true);
        }
        else {
          onApiError(response);
        }
        setSearching(false);
      });

    }
  }

  const onApiError = (response) => {
    if (response.status === 401) {
      props.logout();
    }
    else {
      setMessage({
        open: true,
        autoHideDuration: null,
        severity: "error",
        title: "Error",
        messages: ["Ha ocurrido un error. Por favor inténtelo de nuevo."] });
    }
  }

  return (
    <div className={classes.root}>
      <ProductsToolbar
        cart={props.cart}
        searching={searching}
        handleAddAll={() => handleAddProducts(selectedProducts, true)}
        handleSearch={(glmId, lineId, groupId, description) => handleSearch(glmId, lineId, groupId, description)}
        onApiError={(response) => onApiError(response)}
        onSale={props.onSale}
      />
      {props.cart ?
      <div className={classes.content}>
        {products.length === 0 ?
        <Card>
          <CardContent>
            <Typography align="center">
              {madeSearch ? "No se han encontrado resultados para su búsqueda." : "Por favor seleccione los filtros para realizar su búsqueda."}
              </Typography>
            </CardContent>
        </Card>
        : <ProductsTable
          type={"product"}
          products={products}
          page={page}
          selectedProducts={selectedProducts}
          cart={props.cart}
          setPage={(page) => setPage(page)}
          updateProducts={(products) => updateProducts(products)}
          handleAddProduct={(product) => handleAddProducts([product], false)}
          handleSelectProducts={(products) => handleSelectProducts(products)}
          onApiError={(response) => onApiError(response)}
        />}
        <Snackbar
          open={message.open}
          autoHideDuration={message.autoHideDuration}
          severity={message.severity}
          title={message.title}
          messages={message.messages}
          handleClose={() => handleClose()}
        />
        <Dialog
          open={openSearchConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Buscar productos"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Si realiza una nueva búsqueda se removerá la selección actual de productos ¿Está seguro?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleSearchConfirm(true)} color="primary" autoFocus>
              Sí
            </Button>
            <Button onClick={() => handleSearchConfirm(false)} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      : <ProductCard className={classes.content} onSale={props.onSale ? true : false} />}
    </div>
  );
}

const mapStateToProps = state => ({
  cart: state.cart
});

const mapDispatchToProps = dispatch => {
  return {
    addItem: (cart, item) => dispatch(addItem(cart, item)),
    logout: () => {
      dispatch(removeCart());
      dispatch(logout());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
