import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { logout } from '../../actions/authentication';
import { remove as removeCart } from '../../actions/cart';
import { Snackbar } from '../../components';
import { AccountDetails, AccountToolbar, Password } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  account: {
    marginTop: 1
  }
}));

const Account = (props) => {
  const classes = useStyles();

  const [message, setMessage] = useState({ open: false, autoHideDuration: null, severity: "success", title: "", messages: [] });

  const handleClose = () => {
    setMessage({ ...message, open: false });
  };

  const onApiSuccess = (title, message) => {
    setMessage({
      open: true,
      autoHideDuration: null,
      severity: "success",
      title: title,
      messages: [message] });
  }

  const onApiError = (response) => {
    if (response.status === 401) {
      props.logout();
    }
    else {
      setMessage({
        open: true,
        autoHideDuration: null,
        severity: "error",
        title: "Error",
        messages: [response.data.message] });
    }
  }

  return (
    <div className={classes.root}>
      <AccountToolbar />
      <Grid
        container
        spacing={4}
        className={classes.account}
      >
        <Grid
          item
          lg={8}
          md={6}
          xl={8}
          xs={12}
        >
          <AccountDetails onApiError={(response) => onApiError(response)} />
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <Password onApiSuccess={(title, message) => onApiSuccess(title, message)} onApiError={(response) => onApiError(response)} />
        </Grid>
      </Grid>
      <Snackbar
          open={message.open}
          autoHideDuration={message.autoHideDuration}
          severity={message.severity}
          title={message.title}
          messages={message.messages}
          handleClose={() => handleClose()}
        />
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(removeCart());
      dispatch(logout());
    },
  };
}

export default connect(null, mapDispatchToProps)(Account);