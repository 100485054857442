import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BallotIcon from '@material-ui/icons/Ballot';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import HomeIcon from '@material-ui/icons/Home';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';

import { Profile, SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Home',
      href: '/home',
      icon: <HomeIcon />
    },
    {
      title: 'Productos',
      href: '/products',
      icon: <ShoppingBasketIcon />
    },
    {
      title: 'Productos en oferta',
      href: '/on-sale',
      icon: <CardGiftcardIcon />
    },
    {
      title: 'Mi catálogo',
      href: '/my-catalog',
      icon: <ShoppingBasketIcon />
    },
    {
      title: 'Agro Power',
      href: '/agro-power',
      icon: <ShoppingBasketIcon />
    },
    {
      title: 'Acuerdos de venta',
      href: '/agreement',
      icon: <AssignmentTurnedIn />
    },
    {
      title: 'Cotizaciones',
      href: '/offers',
      icon: <AttachMoneyIcon />
    },
    {
      title: 'Pedidos',
      href: '/orders',
      icon: <BallotIcon />
    },
    {
      title: 'Entregas',
      href: '/deliveries',
      icon: <AirportShuttleIcon />
    },
    {
      title: 'Cuenta',
      href: '/account',
      icon: <AccountBoxIcon />
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
