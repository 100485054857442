import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Select, MenuItem, FormControl } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        flexBasis: 420
    },
    select: {
        backgroundColor: "#FFFFFF"
    },
}));

const SearchSelect = props => {
  const { className, onChange, style, emptyValue, ...rest } = props;

  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
        <Select
          {...rest}
          displayEmpty
          value={""}
          className={classes.select}
        >
          <MenuItem value="">
            <em>{emptyValue}</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
    </FormControl>
  );
};

SearchSelect.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object
};

export default SearchSelect;
