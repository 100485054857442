import { STORAGE_CART } from '../constants/cart';

export const loadState = () => {
    try {
      const serializedState = localStorage.getItem(STORAGE_CART);
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
}

export const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem(STORAGE_CART, serializedState);
    } catch {
      // ignore write errors
    }
}