import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { CartToolbar } from './components';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { updateItem, removeItem } from '../../actions/cart';
import history from '../../helpers/history';
import { CartTable, CartEmpty } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
}));

const Cart = (props) => {
  
  const classes = useStyles();

  const confirmCart = () => {
    history.push('/checkout');
  };

  if (props.cart) {
  
    return (
      <div className={classes.root}>
        <CartToolbar confirmCart={() => confirmCart()} />
        <div className={classes.content}>
          {props.cart.items.length === 0 ?
            <CartEmpty message={"Aún no poseé productos. Puede ir agregándolos desde la sección de productos."} /> :
            <CartTable
              cart={props.cart}
              updateItem={(item) => props.updateItem(props.cart, item)}
              deleteItem={(item) => props.deleteItem(props.cart, item)} 
              />}
        </div>
      </div>
    );
  }

  return <Redirect to={"/products"} />;
};

const mapStateToProps = state => ({
  cart: state.cart
});

const mapDispatchToProps = dispatch => {
  return {
    updateItem: (cart, item) => dispatch(updateItem(cart, item)),
    deleteItem: (cart, item) => dispatch(removeItem(cart, item)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
