import { get } from './index';

export const line = (type) => {
    return get("api/" + type + "/line");
}

export const machine = (type, line) => {
    return get("api/" + type + "/machine?CatalogoLinea=" + line);
}

export const part = (type, line, machine) => {
    return get("api/" + type + "/part?CatalogoLinea=" + line + "&CatalogoMaquina=" + machine);
}

export const search = (type, line, machine, part, code, description) => {
    return get("api/" + type + "?CatalogoLinea=" + line + "&CatalogoMaquina=" + machine + "&CatalogoParte=" + part + "&Substitute=" + code + "&Desc=" + description);
}