import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  Hidden
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { password as apiPassword } from '../../../../api/authentication';

const useStyles = makeStyles(() => ({
  root: { }
}));

const Password = props => {
  const { className, onApiSuccess, onApiError, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    current: '',
    new: '',
    confirm: ''
  });
  const [disabled, setDisabled] = useState(true);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    setDisabled(values.current.length === 0 || values.new.length === 0 || values.confirm.length === 0);
  });

  const onSend = () => {

    apiPassword(values.current, values.new, values.confirm).then(response => {
      if (response.status === 200 && response.data.status) {
        setValues({
          current: '',
          new: '',
          confirm: ''
        });
        onApiSuccess("Cambiar contraseña", response.data.message);
      }
      else {
        onApiError(response);
      }
    });

  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          subheader="Cambiar contraseña"
          title="Contraseña"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Contraseña actual"
            name="current"
            onChange={handleChange}
            type="password"
            value={values.current}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Contraseña nueva"
            name="new"
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            type="password"
            value={values.new}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Confirmar contraseña nueva"
            name="confirm"
            onChange={handleChange}
            style={{ marginTop: '1rem', marginBottom: "4px" }}
            type="password"
            value={values.confirm}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="secondary"
            variant="contained"
            disabled={disabled}
            onClick={() => onSend()}
          >
            <Hidden smUp><SendIcon /></Hidden>
            <Hidden xsDown>Cambiar</Hidden>
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
