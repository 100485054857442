import { get } from './index';

export const glm = () => {
    return get("api/item/glm");
}

export const line = (glm) => {
    return get("api/item/line?U_GLM=" + glm);
}

export const group = (glm, line) => {
    return get("api/item/group?U_GLM=" + glm + "&U_Linea=" + line);
}

export const search = (glm, line, group, description, onSale) => {
    return get("api/items?U_GLM=" + glm + "&U_Linea=" + line + "&ItemsGroupCode=" + group + "&ItemName=" + description + "&U_Oferta=" + (onSale ? "true" : "false"));
}

export const unit = (items) => {
    return get("api/item/unit?items=" + items.join(","));
}