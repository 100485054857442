import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import { TYPE_OFFER, TYPE_ORDER } from '../../constants/cart';
import { checkout as apiCheckout } from '../../api/checkout';
import { getDeliveryDate } from '../../helpers/cart';
import { toDate } from '../../helpers/format';
import { encodeFile } from '../../helpers/file';
import { DropzoneArea } from 'material-ui-dropzone';

const useStyles = makeStyles(() => ({
  root: { }
}));

const CartDetails = props => {
  
  const { className, type, cart, title, disabled, showReference, onApiError, onValuesChange, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    documentDate: '',
    validityDate: '',
    deliveryDate: '',
    clientName: '',
    paymentCondition: '',
    employee: '',
    clientReference: '',
    comments: '',
    discount: 0,
    volumeDiscount: 0,
  });

  useEffect(() => {
    apiCheckout(cart.type, cart.id).then(response => {
      if (response.status === 200 && response.data.status) {
        const data = response.data.data;
        const values = {
          documentDate: toDate(data.document_date),
          validityDate: toDate(data.validity_date),
          deliveryDate: toDate(getDeliveryDate(cart.items)),
          clientName: data.client_name,
          paymentCondition: data.payment_condition,
          employee: data.employee,
          clientReference: data.client_reference,
          comments: data.comments,
          discount: data.discount,
          volumeDiscount: data.volume_discount,
        };
        setValues(values);
        onValuesChange(values);
      }
      else
        onApiError(response);
    });
  }, []);

  const handleChange = event => {
    const newValues = {
      ...values,
      [event.target.name]: event.target.value
    };
    setValues(newValues);
    onValuesChange(newValues);
  };

  const handleFileChange = (files) => {
    if (files.length === 0)
      handleChange({ target: { name: "file", value: undefined } });
    else {
      encodeFile(files[0]).then(encodedFile => {
        handleChange({ target: { name: "file", value: encodedFile } });
      });
    }
  }
  
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader="Información de cabecera"
          title={title}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            {showReference ?
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="# Referencia"
                margin="dense"
                value={cart.reference}
                variant="outlined"
                InputProps={{ readOnly: true }}
              />
            </Grid>
            : null}
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Fecha de documento"
                margin="dense"
                value={values.documentDate}
                variant="outlined"
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={type === TYPE_OFFER ? "Fecha de validez" : "Fecha de entrega"}
                margin="dense"
                value={type === TYPE_OFFER ? values.validityDate : values.deliveryDate}
                variant="outlined"
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Cliente"
                margin="dense"
                value={values.clientName}
                variant="outlined"
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Condición de pago"
                margin="dense"
                value={values.paymentCondition}
                variant="outlined"
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Empleado de venta ATC"
                margin="dense"
                value={values.employee}
                variant="outlined"
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="# Referencia de cliente"
                margin="dense"
                name="clientReference"
                value={values.clientReference}
                onChange={handleChange}
                variant="outlined"
                InputProps={disabled ? { readOnly: true } : { }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                multiline
                rows={6}
                label="Comentarios"
                margin="dense"
                name="comments"
                value={values.comments}
                onChange={handleChange}
                variant="outlined"
                InputProps={disabled ? { readOnly: true } : { }}
              />
            </Grid>
            {!disabled && type === TYPE_ORDER &&
            <Grid item md={12} xs={12}>
              <DropzoneArea
                acceptedFiles={["application/pdf"]}
                onChange={(files) => handleFileChange(files)}
                dropzoneText={"Haga click aquí para agregar la órden de compra"}
                filesLimit={1}
                showAlerts={false}
              />
            </Grid>}
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

CartDetails.propTypes = {
  className: PropTypes.string
};

export default CartDetails;
