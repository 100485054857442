import { get, post, upload } from './index';

export const checkout = (type, id) => {
    return get("api/" + type + "/checkout" + (id ? ("/" + id) : ''));
}

export const save = (type, data) => {
    return post("api/" + type, data);
}

export const stock = (type, data) => {
    return post("api/" + type + "/stock", data);
}