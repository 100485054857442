import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { TextField, MenuItem } from '@material-ui/core';
import { getYears } from '../../helpers/filters';

const useStyles = makeStyles(theme => ({
  root: { },
  formControl: {
    margin: theme.spacing(1),
    backgroundColor: "#FFFFFF",
    width: '200px',
  },
}));

const YearFilter = (props) => {
  
  const { year, setYear } = props;

  const classes = useStyles();

  const years = getYears();

  const onYearChange = (value) => {
    setYear(value);
  }

  return (
        <TextField select variant="outlined" value={year} label="Año" className={classes.formControl} onChange={(e) => onYearChange(e.target.value)}>
            {years.map((item) => (
                <MenuItem key={"year_" + item} value={item}>
                    {item}
                </MenuItem>
            ))}
    </TextField>
  );
};

export default YearFilter;
