import React from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        cursor: 'pointer'
    },
  }));

const SortingColumn = props => {

    const classes = useStyles();

    const arrow = props.sort === props.currentSort ? (props.currentSortDirection === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />) : null;

    const onClick = () => {
        const sortDirection = props.sort === props.currentSort ? (props.currentSortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
        return props.onSorting(props.sort, sortDirection);
    };

    return (
        <TableCell className={classes.root} onClick={() => onClick()}>{arrow} {props.label}</TableCell>
    );

}

export default SortingColumn;