import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import { account as apiAccount } from '../../../../api/authentication';
import { toCurrency } from '../../../../helpers/format';

const useStyles = makeStyles(() => ({
  root: {}
}));

const AccountDetails = props => {
  const { className, onApiError, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    code: '',
    name: '',
    employee: '',
    paymentCondition: '',
    amount: ''
  });

  useEffect(() => {
    apiAccount().then(response => {
      if (response.status === 200 && response.data.status) {
        const data = response.data.data[0];
        setValues({
          code: data.CardCode,
          name: data.CardName,
          employee: data.OwnerCode + " - " + data.lastName + " " + data.firstName,
          paymentCondition: data.PymntGroup,
          amount: toCurrency(data.Balance, 'ARS')
        });
      }
      else
        onApiError(response);
    });
  }, []);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader="Información no editable"
          title="Datos de la cuenta"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Código"
                margin="dense"
                InputProps={{ readOnly: true }}
                value={values.code}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nombre"
                margin="dense"
                InputProps={{ readOnly: true }}
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Empleado de Venta ATC"
                margin="dense"
                InputProps={{ readOnly: true }}
                value={values.employee}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Condición de Pago"
                margin="dense"
                InputProps={{ readOnly: true }}
                value={values.paymentCondition}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Saldo de cuenta"
                margin="dense"
                InputProps={{ readOnly: true }}
                value={values.amount}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
