import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Button,
  Divider
} from '@material-ui/core';
import { connect } from 'react-redux';
import { create as createCart } from '../../../../actions/cart';
import { TYPE_OFFER } from '../../../../constants/cart';

const useStyles = makeStyles(theme => ({
  root: {},
  imageContainer: {
    height: 64,
    width: 64,
    margin: '0 auto',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    width: '100%'
  },
  statsItem: {
    display: 'flex',
    alignItems: 'center'
  },
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1)
  },
  buttonContainer: {
    marginBottom: "20px"
  }
}));

const ProductCard = props => {
  const { className, createCart, type, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Typography
          align="center"
          gutterBottom
          variant="h4"
        >
          ¿Qué desea generar?
        </Typography>
      </CardContent>
      <Divider />
      <CardActions className={classes.buttonContainer}>
        <Grid
          container
          justify="center"
        >
          <Button
          color="primary"
          variant="contained"
          onClick={() => createCart(TYPE_OFFER, type)}
        >
          Nueva cotización
        </Button>
        </Grid>
      </CardActions>
    </Card>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
};

const mapDispatchToProps = dispatch => {
  return {
    createCart: (type, redirect) => dispatch(createCart(type, "/" + redirect)),
  };
}

export default connect(null, mapDispatchToProps)(ProductCard);
