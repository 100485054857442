import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { TextField, MenuItem } from '@material-ui/core';
import { getMonths } from '../../helpers/filters';

const useStyles = makeStyles(theme => ({
  root: { },
  formControl: {
    margin: theme.spacing(1),
    backgroundColor: "#FFFFFF",
    width: '200px',
  },
}));

const MonthFilter = (props) => {
  
  const { month, setMonth } = props;

  const classes = useStyles();

  const months = getMonths();

  const onMonthChange = (value) => {
    setMonth(value);
  }

  return (
        <TextField select variant="outlined" value={month} label="Mes" className={classes.formControl} onChange={(e) => onMonthChange(e.target.value)}>
            {months.map((item) => (
              <MenuItem key={"month_" + item.number} value={item.number}>
                {item.name}
              </MenuItem>
            ))}
        </TextField>
  );
};

export default MonthFilter;
