import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Alert from '@material-ui/lab/Alert';
import { getCartWarnings } from '../../../../helpers/cart';

const useStyles = makeStyles(theme => ({
    warning: {
        marginTop: theme.spacing(2)
    },
  }));

const CheckoutWarning = props => {
  const { type, cart } = props;

  const classes = useStyles();

  const warnings = getCartWarnings(type, cart.items);

  // No warnings
  return null;

  if (warnings.length === 0)
    return null;
    
  return (
    <div>
      {warnings.map(warning => (
          <Alert className={classes.warning} severity="warning">{warning}</Alert>
      ))}
    </div>
  );
};

export default CheckoutWarning;
