import { CART_UPDATE } from '../constants/cart';
import history from '../helpers/history';

export const create = (type, redirect = "/products") => {
    return (dispatch, state) => {
      dispatch(createFrom(type, null, redirect));
    }
}

export const createFrom = (type, cart, redirect = "/products") => {
  return (dispatch, state) => {
    if (cart) {
      dispatch({ type: CART_UPDATE, payload: cart });
      history.push('/cart');
    }
    else {
        dispatch({ type: CART_UPDATE, payload: { type: type, id: null, items: [] } });
        history.push(redirect);
    }
  }
}

export const remove = () => {
    return (dispatch, state) => {
      dispatch({ type: CART_UPDATE, payload: null });
    }
}

export const addItem = (cart, item) => {
  return (dispatch, state) => {
    let newCart = { ...cart };

    item.id = item.id + '_' + item.unit.id;

    const currentItem = newCart.items.find(currentItem => {
      return currentItem.id === item.id;
    });

    if (currentItem)
      currentItem.qty += item.qty;
    else
      newCart.items.push(item);

    dispatch({ type: CART_UPDATE, payload: newCart });
  }
}

export const updateItem = (cart, item) => {
  return (dispatch, state) => {
    dispatch({ type: CART_UPDATE, payload: { ...cart } });
  }
}

export const removeItem = (cart, item) => {
  return (dispatch, state) => {
    let newCart = { ...cart };

    newCart.items = newCart.items.filter(currentItem => {
      return currentItem.id !== item.id;
    });
      
    dispatch({ type: CART_UPDATE, payload: newCart });
  }
}