import {
    LOGIN_REQUEST,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT,
    STORAGE_USER
  } from '../constants/authentication';
  
  const initialState = localStorage.getItem(STORAGE_USER) ? { loggedIn: true } : { loggedIn: false };
  
  export default function authentication(state = initialState, action) {
    switch (action.type) {
      case LOGIN_REQUEST:
          return {
              loginRequested: true,
              loginFailed: false
          };
      case LOGIN_SUCCESS:
        return {
            loggedIn: true,
            loginRequested: false
        };
      case LOGIN_FAILURE:
        return {
          loginRequested: false,
          loggedIn: false,
          loginFailed: true,
          loginError: action.payload,
        };
      case LOGOUT:
        return {
          loggedIn: false,
        };
      default:
        return state;
    }
  }