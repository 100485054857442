import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { toCurrency, toDate } from '../../helpers/format';
import { getCartTotal } from '../../helpers/cart';
import { TAX, TYPE_OFFER } from '../../constants/cart';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  price: {
    width: 100
},
qty: {
    width: 100
},
unit: {
  width: 180
},
}));

const CartList = props => {
  const { className, cart, details, calculateTotals, ...rest } = props;

  const classes = useStyles();

  const subTotal = calculateTotals ? (+getCartTotal(cart).toFixed(2)) : (cart.total - cart.total_tax + cart.total_discount);
  const discount = calculateTotals ? (details ? -(subTotal * details.discount / 100).toFixed(2) : 0) : cart.total_discount;
  const taxes = calculateTotals ? (+((subTotal + discount) * TAX / 100).toFixed(2)) : cart.total_tax;
  const total = calculateTotals ? (+(subTotal + discount + taxes).toFixed(2)) : cart.total;

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
      <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell>{cart.type === TYPE_OFFER ? "Plazo de entrega" : "Fecha de entrega"}</TableCell>
                  <TableCell className={classes.price}>Precio</TableCell>
                  <TableCell className={classes.qty}>Cantidad</TableCell>
                  <TableCell className={classes.unit}>UDM</TableCell>
                  <TableCell className={classes.price}>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cart.items.map(item => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={item.id}
                  >
                    <TableCell>
                        {item.code}
                    </TableCell>
                    <TableCell>
                        {item.description}
                    </TableCell>
                    <TableCell>
                      {cart.type === TYPE_OFFER ? item.term : toDate(item.delivery)}
                    </TableCell>
                    <TableCell>
                      {toCurrency(item.unit.price)}
                    </TableCell>
                    <TableCell>
                        {item.qty}
                    </TableCell>
                    <TableCell>
                        {item.unit.name}
                    </TableCell>
                    <TableCell>
                      {toCurrency(calculateTotals ? (item.qty * item.unit.price) : item.total)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>Subtotal</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell className={classes.price}></TableCell>
                  <TableCell className={classes.qty}></TableCell>
                  <TableCell className={classes.unit}></TableCell>
                  <TableCell className={classes.price}>{toCurrency(subTotal)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Descuento</TableCell>
                  <TableCell>({details ? (details.discount + "%") : ''})</TableCell>
                  <TableCell></TableCell>
                  <TableCell className={classes.price}></TableCell>
                  <TableCell className={classes.qty}></TableCell>
                  <TableCell className={classes.unit}></TableCell>
                  <TableCell className={classes.price}>{toCurrency(discount)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Impuestos</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell className={classes.price}></TableCell>
                  <TableCell className={classes.qty}></TableCell>
                  <TableCell className={classes.unit}></TableCell>
                  <TableCell className={classes.price}>{toCurrency(taxes)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell className={classes.price}></TableCell>
                  <TableCell className={classes.qty}></TableCell>
                  <TableCell className={classes.unit}></TableCell>
                  <TableCell className={classes.price}>{toCurrency(total)}</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

CartList.propTypes = {
  className: PropTypes.string
};

export default CartList;
