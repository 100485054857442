export const getStatusName = (status) => {
    switch (status) {
        case "A":
            return "Abierto";
        case "P":
            return "Pasado a pedido";
        case "E":
            return "Pasado a entrega";
        case "F":
            return "Pasado a factura";
        default:
            return "Cerrado";
    }
}