import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Typography, CircularProgress, Grid, Hidden } from '@material-ui/core';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import { getDictionary } from '../../../../helpers/cart';
import { TextField, MenuItem } from '@material-ui/core';
import { glm as apiGlm, line as apiLine, group as apiGroup } from '../../../../api/pruduct';

const useStyles = makeStyles(theme => ({
  root: { },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  },
  formControl: {
    width: "100%",
    marginTop: theme.spacing(1),
    backgroundColor: "#FFFFFF"
  },
  searchRow: {
    marginTop: theme.spacing(2),
    textAlign: "right"
  },
  searchButton: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginLeft: 10
  },
  progress: {
    marginRight: theme.spacing(2)
  },
}));

const ProductsToolbar = props => {
  const { className, handleAddAll, handleSearch, onApiError, searching, ...rest } = props;

  const classes = useStyles();

  const [glms, setGlms] = useState([]);
  useEffect(() => {
    apiGlm().then(response => {
      if (response.status === 200 && response.data.status)
        setGlms(response.data.data);
      else
        onApiError(response);
    });
  }, []);
  
  const [lines, setLines] = useState([]);
  const [groups, setGroups] = useState([]);
  
  const [glmId, setGlmId] = useState("");
  const [lineId, setLineId] = useState("");
  const [groupId, setGroupId] = useState("");
  const [description, setDescription] = useState("");

  const onGlmChange = (value) => {
    setGlmId(value);
    setLineId("");
    setGroupId("");
    setLines([]);
    setGroups([]);
    apiLine(value).then(response => {
      if (response.status === 200 && response.data.status)
        setLines(response.data.data);
      else
        onApiError(response);
    });
  }

  const onLineChange = (value) => {
    setLineId(value);
    setGroupId("");
    setGroups([]);
    apiGroup(glmId, value).then(response => {
      if (response.status === 200 && response.data.status)
        setGroups(response.data.data);
      else
        onApiError(response);
    });
  }

  const onGroupChange = (value) => {
    setGroupId(value);
  }

  const onDescriptionChange = (value) => {
    setDescription(value);
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
      {props.onSale ? <CardGiftcardIcon /> : <ShoppingBasketIcon />}
      <Typography className={classes.title}
        variant="h4"
      >
        {props.onSale ? "Productos en oferta" : "Productos"}
      </Typography>
      </div>
      {props.cart ?
      <Fragment>
      <Grid container>
        <Grid item md={3} xs={12}>
          <TextField select variant="outlined" value={glmId} label="GLM" className={classes.formControl} onChange={(e) => onGlmChange(e.target.value)}>
            {glms.map((item) => (
                <MenuItem key={"glm_" + item.U_GLM} value={item.U_GLM}>
                  {item.U_GLM}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item md={3} xs={12}>
        <TextField select variant="outlined" value={lineId} label="Línea" className={classes.formControl} onChange={(e) => onLineChange(e.target.value)}>
          {lines.map((item) => (
              <MenuItem key={"line_" + item.U_Linea} value={item.U_Linea}>
                {item.U_Linea}
              </MenuItem>
            ))}
        </TextField>
        </Grid>
        <Grid item md={3} xs={12}>
        <TextField select variant="outlined" value={groupId} label="Grupo" className={classes.formControl} onChange={(e) => onGroupChange(e.target.value)}>
          {groups.map((item) => (
              <MenuItem key={"group_" + item.ItmsGrpCod} value={item.ItmsGrpCod}>
                {item.ItmsGrpNam}
              </MenuItem>
            ))}
        </TextField>
        </Grid>
        <Grid item md={3} xs={12}>
        <TextField variant="outlined" value={description} label="Descripción" className={classes.formControl} onChange={(e) => onDescriptionChange(e.target.value)} />
        </Grid>
      </Grid>
      <Grid className={classes.searchRow}>
        {searching ? <CircularProgress size={30} className={classes.progress} /> :
        <Button
          color="primary"
          variant="contained"
          className={classes.searchButton}
          onClick={() => handleSearch(glmId, lineId, groupId, description)}
        >
          <Hidden smUp><SearchIcon /></Hidden>
          <Hidden xsDown>Buscar</Hidden>
        </Button>}
        <Button
          color="secondary"
          variant="contained"
          onClick={() => handleAddAll()}
        >
          <Hidden smUp><AddIcon /></Hidden>
          <Hidden xsDown>{getDictionary(props.cart.type, 'add_action')}</Hidden>
        </Button>
      </Grid>
      </Fragment>
      : null}

    </div>
  );
};

ProductsToolbar.propTypes = {
  className: PropTypes.string
};

export default ProductsToolbar;
