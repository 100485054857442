import { TYPE_OFFER, TYPE_ORDER } from '../constants/cart';

const dictionary = {
    [TYPE_OFFER]: {
        'add_action': 'Agregar a la cotización',
        'add_title': 'Nueva cotización',
        'edit_title': 'Editar cotización',
        'save_confirm_action': 'Confirmar cotización',
        'cancel_action': 'Cancelar cotización',
        'cancel_edit_action': 'Cancelar edición de cotización',
        'confirm_title': 'Confirmar cotización',
        'confirm_subtitle': 'Datos de la cotización',
        'cancel_confirm_subtitle': '¿Está seguro que desea cancelar esta cotización?',
        'new_confirm': 'Se eliminará la cotización en curso. ¿Está seguro?'
    },
    [TYPE_ORDER]: {
        'add_action': 'Agregar al pedido',
        'add_title': 'Nuevo pedido',
        'edit_title': 'Editar pedido', // No aplica
        'save_confirm_action': 'Confirmar pedido',
        'cancel_action': 'Cancelar pedido',
        'cancel_edit_action': 'Cancelar edición de pedido', // No aplica
        'confirm_title': 'Confirmar pedido',
        'confirm_subtitle': 'Datos del pedido',
        'cancel_confirm_subtitle': '¿Está seguro que desea cancelar este pedido?',
        'new_confirm': 'Se eliminará el pedido en curso. ¿Está seguro?'
    }
}

const glm_warning = "OPTIBELT DENT./POLYV";

export const getDictionary = (type, key) => {
    return dictionary[type][key];
}

export const getCartTotal = (cart) => {
    let total = 0;
    cart.items.map(item => {
        total += item.qty * item.unit.price;
    });
    return total;
}

export const getCartErrors = (items, validateMax = false) => {
    let errors = [];
    if (items.length === 0) {
        errors.push('Debe agregar por lo menos un producto.');
    }
    items.map(item => {
      if (item.qty === 0)
        errors.push('El producto ' + item.code + ' tiene cero cantidad.');
      else if (validateMax && item.maxQty && item.qty > item.maxQty)
        errors.push('El producto ' + item.code + ' no puede tener una cantidad mayor a ' + item.maxQty + '.');
    });
    return errors;
}

export const getCartDuplicatesErrors = (cart, itemsToAdd) => {
    let errors = [];
    cart.items.forEach(item => {
        itemsToAdd.forEach(itemToAdd => {
            if (item.code === itemToAdd.code && item.unit.id === itemToAdd.unit.id && item.catalog !== itemToAdd.catalog)
                errors.push('El producto ' + item.code + ' ya se encuentra agregado desde ' + (item.catalog ? 'catálogo' : 'productos') + '.');
        });
    });
    return errors;
}

export const getCartWarnings = (type, items) => {
    let warnings = [];
    items.map(item => {
        if (item.glm === glm_warning)
            warnings.push('En caso de no disponer material para las medidas solicitadas del producto ' + item.code + ', se le informará a la brevedad' + (type === TYPE_OFFER ? ' al momento de pasar el pedido' : '') + '.');
    });
    return warnings;
}

export const getDeliveryDate = (items) => {
    let deliveryDate = null;
    items.map(item => {
        if (!deliveryDate || item.delivery > deliveryDate)
            deliveryDate = item.delivery;
    });
    return deliveryDate;
}

export const getTerm = (item) => {
    if (item.code.includes("_"))
        return 999;
    return item.term;
}