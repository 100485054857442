import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  ProductList as ProductListView,
  OnSale as OnSaleView,
  MyCatalogList as MyCatalogListView,
  AgroPowerList as AgroPowerListView,
  AgreementList as AgreementListView,
  Account as AccountView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Cart as CartView,
  OfferList as OfferListView,
  Offer as OfferView,
  OfferToOrder as OfferToOrderView,
  OrderList as OrderListView,
  Order as OrderView,
  DeliveryList as DeliveryListView,
  Delivery as DeliveryView,
  Checkout as CheckoutView,
  Home as HomeView,
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/home"
      />
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MainLayout}
        path="/home"
        isPrivate={true}
      />
      <RouteWithLayout
        component={OfferListView}
        exact
        layout={MainLayout}
        path="/offers"
        isPrivate={true}
      />
      <RouteWithLayout
        component={OfferView}
        exact
        layout={MainLayout}
        path="/offer/:id"
        isPrivate={true}
      />
      <RouteWithLayout
        component={OfferToOrderView}
        exact
        layout={MainLayout}
        path="/offer-to-order/:id"
        isPrivate={true}
      />
      <RouteWithLayout
        component={OrderListView}
        exact
        layout={MainLayout}
        path="/orders"
        isPrivate={true}
      />
      <RouteWithLayout
        component={OrderView}
        exact
        layout={MainLayout}
        path="/order/:id"
        isPrivate={true}
      />
      <RouteWithLayout
        component={DeliveryListView}
        exact
        layout={MainLayout}
        path="/deliveries"
        isPrivate={true}
      />
      <RouteWithLayout
        component={DeliveryView}
        exact
        layout={MainLayout}
        path="/delivery/:id"
        isPrivate={true}
      />
      <RouteWithLayout
        component={CheckoutView}
        exact
        layout={MainLayout}
        path="/checkout"
        isPrivate={true}
      />
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
        isPrivate={true}
      />
      <RouteWithLayout
        component={OnSaleView}
        exact
        layout={MainLayout}
        path="/on-sale"
        isPrivate={true}
      />
      <RouteWithLayout
        component={MyCatalogListView}
        exact
        layout={MainLayout}
        path="/my-catalog"
        isPrivate={true}
      />
      <RouteWithLayout
        component={AgroPowerListView}
        exact
        layout={MainLayout}
        path="/agro-power"
        isPrivate={true}
      />
      <RouteWithLayout
        component={AgreementListView}
        exact
        layout={MainLayout}
        path="/agreement"
        isPrivate={true}
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
        isPrivate={true}
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/login"
        isPrivate={false}
      />
      <RouteWithLayout
        component={CartView}
        exact
        layout={MainLayout}
        path="/cart"
        isPrivate={true}
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
