import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Typography, Grid, Hidden } from '@material-ui/core';
import {
  Add as AddIcon,
  Check as CheckIcon,
  Clear as ClearIcon,
  ShoppingCart as ShoppingCartIcon
} from '@material-ui/icons';
import { connect } from 'react-redux';
import { remove as removeCart } from '../../../../actions/cart';
import { logout } from '../../../../actions/authentication';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getDictionary } from '../../../../helpers/cart';
import { Snackbar } from '../../../../components';
import { getCartErrors } from '../../../../helpers/cart';
import history from '../../../../helpers/history';
import { stock as apiStock } from '../../../../api/checkout';

const useStyles = makeStyles(theme => ({
  root: { },
  row: {
    marginTop: theme.spacing(2)
  },
  innerRow: {
    display: 'flex'
  },
  buttonRow: {
    textAlign: 'right',
    paddingTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  searchButton: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginLeft: 10
  }
}));

const CartToolbar = props => {
  const { className, confirmCart, removeCart, ...rest } = props;

  const classes = useStyles();

  const titleText = getDictionary(props.cart.type, props.cart.id ? 'edit_title' : 'add_title');
  const saveText = getDictionary(props.cart.type, 'save_confirm_action');
  const cancelText = getDictionary(props.cart.type, props.cart.id ? 'cancel_edit_action' : 'cancel_action');
  const cancelConfirmText = getDictionary(props.cart.type, 'cancel_confirm_subtitle');

  const [open, setOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const handleDeleteOpen = () => {
    setOpen(true);
  };

  const handleDeleteClose = (mustDelete) => {
    if (mustDelete)
      removeCart();
    setOpen(false);
  };

  const validateCart = (cart) => {
    let errors = getCartErrors(cart.items);
    if (errors.length === 0) {

      // Valido stock
      apiStock(props.cart.type, cart).then(response => {
        if (response.status === 200) {

          if (response.data.status) {
            confirmCart();
          }
          else {
            setErrorMessages(response.data.data);
            setErrorOpen(true);
          }
          
        }
        else {
          onApiError(response);
        }
        
      });
      
    }
    else {
      setErrorMessages(errors);
      setErrorOpen(true);
    }
      
  }

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const onApiError = (response) => {
    if (response.status === 401) {
      props.logout();
    }
    else {
      setErrorMessages(["Ha ocurrido un error. Por favor inténtelo de nuevo."]);
      setErrorOpen(true);
    }
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
      <Grid container>
      <Grid item className={classes.innerRow} md={6} xs={12}>
      <ShoppingCartIcon />
      <Typography className={classes.title}
        variant="h4"
      >
        {titleText}
      </Typography>
      </Grid>
      <Grid item className={classes.buttonRow} md={6} xs={12}>
        {props.cart.items.length > 0 &&
        <Button
          color="primary"
          variant="contained"
          className={classes.searchButton}
          onClick={() => validateCart(props.cart)}
        >
          <Hidden smUp><CheckIcon /></Hidden>
          <Hidden xsDown>{saveText}</Hidden>
        </Button>
        }
        <Button
          color="secondary"
          variant="contained"
          className={classes.searchButton}
          onClick={() => history.push('/products')}
        >
          <Hidden smUp><AddIcon /></Hidden>
          <Hidden xsDown>{"Agregar más productos"}</Hidden>
        </Button>
        <Button
          color="default"
          variant="contained"
          onClick={() => handleDeleteOpen()}
        >
          <Hidden smUp><ClearIcon /></Hidden>
          <Hidden xsDown>{cancelText}</Hidden>
        </Button>
        </Grid>
        </Grid>
      </div>
        <Dialog
          open={open}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{cancelText}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {cancelConfirmText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleDeleteClose(true)} color="primary" autoFocus>
              Sí
            </Button>
            <Button onClick={() => handleDeleteClose(false)} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={errorOpen}
          autoHideDuration={null}
          severity={"error"}
          title={"Error"}
          messages={errorMessages}
          handleClose={() => handleErrorClose()}
        />
    </div>
  );
};

CartToolbar.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => ({
  cart: state.cart
});

const mapDispatchToProps = dispatch => {
  return {
    removeCart: () => dispatch(removeCart()),
    logout: () => {
      dispatch(removeCart());
      dispatch(logout());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CartToolbar);
