import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, CircularProgress } from '@material-ui/core';
import { OfferToolbar } from './components';
import { CartList, CartDetails, Snackbar, TableProgress } from '../../components';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { TYPE_OFFER } from '../../constants/cart';
import { MODE_VIEW } from '../../constants/offer';
import history from '../../helpers/history';
import { NewCartDialog } from '../../components';
import { createFrom as createCartFrom, remove as removeCart } from '../../actions/cart';
import { logout } from '../../actions/authentication';
import { getById as apiGetOfferById, download as apiDownload } from '../../api/offer';
import fileDownload from 'js-file-download';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  grid: {
    marginTop: 1
  },
  download: {
    textAlign: "center"
  }
}));

const Offer = (props) => {
    
    const classes = useStyles();

    const { id } = useParams();
    const [cart, setCart] = useState(null);
    const [openNewOfferConfirm, setOpenNewOfferConfirm] = useState(false);
    const [details, setDetails] = useState(null);
    const [message, setMessage] = useState({ open: false, autoHideDuration: null, severity: "success", title: "", messages: [] });
    const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);

    const handleClose = () => {
        setMessage({ ...message, open: false });
    };

    const onApiError = (response) => {
        if (response.status === 401) {
        props.logout();
        }
        else {
          setMessage({
            open: true,
            autoHideDuration: null,
            severity: "error",
            title: "Error",
            messages: response.data.data ? response.data.data : [response.data.message] });
        }
    }

    useEffect(() => {
        apiGetOfferById(id).then(response => {
          if (response.status === 200 && response.data.status)
            setCart(response.data.data);
          else
            onApiError(response);
        });
    }, []);

    const newOfferConfirm = (confirm) => {
        setOpenNewOfferConfirm(false);
        if (confirm)
            setNewOffer();
    }

    const onEditOffer = () => {
        if (props.cart)
            setOpenNewOfferConfirm(true);
        else
            setNewOffer();
    }

    const setNewOffer = () => {
        apiGetOfferById(id).then(response => {
            if (response.status === 200 && response.data.status) {
                props.createCartFrom(TYPE_OFFER, response.data.data);
            }
            else {
                // Handle error
                onApiError(response);
            }
        });
    }
    
    const onOfferToOrder = () => {
        history.push('/offer-to-order/' + id);
    }

    const onDetailsChange = (values) => {
        setDetails(values);
    }

    const onDownload = () => {
      setDownloadDialogOpen(true);
      apiDownload(id).then(response => {
        if (response.status === 201) {
            fileDownload(response.data, id + ".pdf");
            setDownloadDialogOpen(false);
        }
        else {
            // Handle error
 	      onDownload()
            //onApiError({ data: { message: "Ha ocurrido un error tratando de imprimir la cotización. Por favor inténtelo en unos minutos." } });
        }
       
      });
    }

    return (
    <div className={classes.root}>
        {cart ?
            <Fragment>
                <OfferToolbar
                    title={"Cotización # " + cart.reference}
                    cart={cart}
                    mode={MODE_VIEW}
                    onNewOffer={() => onEditOffer()}
                    onOfferToOrder={() => onOfferToOrder()}
                    onDownload={() => onDownload()}
                    onFiltersChange={() => { }}
                />
                <Grid container spacing={4} className={classes.grid} >
                    <Grid item lg={4} md={6} xl={4} xs={12}>
                        <CartDetails
                        type={cart.type}
                        cart={cart}
                        title={"Datos de la cotización"}
                        disabled={true}
                        showReference={true}
                        back={"/offers"}
                        onApiError={(response) => onApiError(response)}
                        onValuesChange={(values) => onDetailsChange(values)}
                        />
                    </Grid>
                    <Grid item lg={8} md={6} xl={8} xs={12}>
                        <CartList cart={cart} details={details} calculateTotals={false} />
                    </Grid>
                </Grid>
            </Fragment>
        : <TableProgress />}
        <NewCartDialog
            open={openNewOfferConfirm}
            type={TYPE_OFFER}
            cart={props.cart}
            id={id}
            onConfirm={(confirm, id) => newOfferConfirm(confirm)}
        />
        <Snackbar
          open={message.open}
          autoHideDuration={message.autoHideDuration}
          severity={message.severity}
          title={message.title}
          messages={message.messages}
          handleClose={() => handleClose()}
        />
        <Dialog
          open={downloadDialogOpen}
          aria-labelledby="download-dialog-title"
        >
          <DialogTitle id="download-dialog-title">{"Descargando, este proceso puede demorar unos segundos"}</DialogTitle>
          <DialogContent className={classes.download}>
            <CircularProgress />
          </DialogContent>
        </Dialog>
    </div>
    );
};

const mapStateToProps = state => ({
    cart: state.cart
});

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(removeCart());
            dispatch(logout());
          },
          createCartFrom: (type, id) => dispatch(createCartFrom(type, id)),
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(Offer);