import { get } from './index';

export const line = () => {
    return get("api/agreement/line");
}

export const machine = (line) => {
    return get("api/agreement/machine?CatalogoLinea=" + line);
}

export const part = (line, machine) => {
    return get("api/agreement/part?CatalogoLinea=" + line + "&CatalogoMaquina=" + machine);
}

export const search = (line, machine, part, code, description) => {
    return get("api/agreement?CatalogoLinea=" + line + "&CatalogoMaquina=" + machine + "&CatalogoParte=" + part + "&Substitute=" + code + "&Desc=" + description);
}