export const encodeFile = async (file) => {
  const filename = file.name;
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64 = reader.result?.toString() || "";
      resolve({
        filename,
        base64
      });
    };
  });
}
