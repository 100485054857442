import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Card, CardContent, Typography } from '@material-ui/core';
import { OfferToolbar, OfferTable } from './components';
import { NewCartDialog, Snackbar, SuccessSnackbar, TableProgress } from '../../components';
import { create as createCart, createFrom as createCartFrom, remove as removeCart } from '../../actions/cart';
import { logout } from '../../actions/authentication';
import { TYPE_OFFER } from '../../constants/cart';
import { MODE_LIST } from '../../constants/offer';
import history from '../../helpers/history';
import { search as apiSearch, getById as apiGetOfferById } from '../../api/offer';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
}));

const OfferList = (props) => {

  const classes = useStyles();

  const [message, setMessage] = useState({ open: false, autoHideDuration: null, severity: "success", title: "", messages: [] });
  const [offers, setOffers] = useState([]);
  const [page, setPage] = useState(0);
  const [openNewOfferConfirm, setOpenNewOfferConfirm] = useState(false);
  const [newOfferConfirmId, setNewOfferConfirmId] = useState(null);
  const [searching, setSearching] = useState(false);

  const newOfferConfirm = (confirm, id) => {
    setOpenNewOfferConfirm(false);
    if (confirm)
      setNewOffer(id);
  }

  const onNewOffer = (id) => {
    setNewOfferConfirmId(id);
    if (props.cart)
      setOpenNewOfferConfirm(true);
    else
      setNewOffer(id);
  }

  const setNewOffer = (id) => {
    if (id) {
      apiGetOfferById(id).then(response => {
        if (response.status === 200 && response.data.status) {
          props.createCartFrom(TYPE_OFFER, response.data.data);
        }
        else {
          // Handle error
          onApiError(response);
        }
      });
    }
    else {
      props.createCart(TYPE_OFFER);
    }
  }

  const onViewOffer = (id) => {
    history.push('/offer/' + id);
  }

  const onOfferToOrder = (id) => {
    history.push('/offer-to-order/' + id);
  }

  // Search
  const handleSearch = (year, month) => {
    setSearching(true);
    apiSearch(year, month).then(response => {
      if (response.status === 200 && response.data.status) {
        // Map offers
        setPage(0);
        setOffers(response.data.data);
      }
      else {
        onApiError(response);
      }
      setSearching(false);
    });
  }
  
  const onApiError = (response) => {
    if (response.status === 401) {
      props.logout();
    }
    else {
      setMessage({
        open: true,
        autoHideDuration: null,
        severity: "error",
        title: "Error",
        messages: ["Ha ocurrido un error. Por favor inténtelo de nuevo."] });
    }
  }

  const handleClose = () => {
    setMessage({ ...message, open: false });
  };

  if (props.error) {
    setMessage({
      open: true,
      autoHideDuration: null,
      severity: "error",
      title: "Error",
      messages: ["Ha ocurrido un error. Por favor inténtelo de nuevo."] });
    props.setError(null);
  }

  return (
    <div className={classes.root}>
      <OfferToolbar title={"Cotizaciones"} mode={MODE_LIST} onNewOffer={() => onNewOffer(null)} onFiltersChange={(year, month) => handleSearch(year, month)} />
      <div className={classes.content}>
      {searching ? <TableProgress /> :
        offers.length === 0 ?
        <Card>
          <CardContent>
            <Typography align="center">
              {"No se han encontrado resultados para su búsqueda."}
              </Typography>
            </CardContent>
        </Card>
        : 
        <OfferTable
          offers={offers}
          page={page}
          setPage={(page) => setPage(page)}
          onViewOffer={(id) => onViewOffer(id)}
          onEditOffer={(id) => onNewOffer(id)}
          onOfferToOrder={(id) => onOfferToOrder(id)}
        />}
      </div>
      <NewCartDialog
        open={openNewOfferConfirm}
        type={TYPE_OFFER}
        cart={props.cart}
        id={newOfferConfirmId}
        onConfirm={(confirm, id) => newOfferConfirm(confirm, id)}
      />
      <Snackbar
          open={message.open}
          autoHideDuration={message.autoHideDuration}
          severity={message.severity}
          title={message.title}
          messages={message.messages}
          handleClose={() => handleClose()}
        />
      <SuccessSnackbar />
    </div>
  );
};

const mapStateToProps = state => ({
  cart: state.cart,
  error: state.error
});

const mapDispatchToProps = dispatch => {
  return {
    createCartFrom: (type, id) => dispatch(createCartFrom(type, id)),
    createCart: (type) => dispatch(createCart(type)),
    logout: () => {
      dispatch(removeCart());
      dispatch(logout());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferList);