import { login as apiLogin, user as apiUser } from "../api/authentication";
import history from '../helpers/history';
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, STORAGE_USER } from '../constants/authentication';

export const login = (username, password) => async (dispatch) => {
  
    dispatch({ type: LOGIN_REQUEST });
    // Get from API (oauth/token) and user info
    apiLogin(username, password)
        .then(response => {
            
            if (response.status === 200) {

                let user = {
                    token: response.data.access_token,
                    refreshToken: response.data.refresh_token
                };

                localStorage.setItem(STORAGE_USER, JSON.stringify(user));

                apiUser()
                    .then(response => {
                        if (response.status === 200 && response.data.status) {

                            user.name = response.data.data.name;

                            localStorage.setItem(STORAGE_USER, JSON.stringify(user));
                            dispatch({ type: LOGIN_SUCCESS });
                            history.push('/');
                        }
                        else {
                            localStorage.removeItem(STORAGE_USER);
                            dispatch({ type: LOGIN_FAILURE, payload: "Credenciales inválidas." });
                        }
                    });
            }
            else {
                dispatch({ type: LOGIN_FAILURE, payload: "Credenciales inválidas." });
            }
        });
    
}

export const logout = () => async (dispatch) => {
    localStorage.removeItem(STORAGE_USER);
    dispatch({ type: LOGOUT });
    history.push('/login');
}