import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Typography, CircularProgress, Grid, Hidden } from '@material-ui/core';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import { getDictionary } from '../../../../helpers/cart';
import { TextField, MenuItem } from '@material-ui/core';
import { line as apiLine, machine as apiMachine, part as apiPart } from '../../../../api/agreement';

const useStyles = makeStyles(theme => ({
  root: { },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  },
  formControl: {
    width: "100%",
    marginTop: theme.spacing(1),
    backgroundColor: "#FFFFFF"
  },
  searchRow: {
    marginTop: theme.spacing(2),
    textAlign: "right"
  },
  searchButton: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginLeft: 10
  },
  progress: {
    marginRight: theme.spacing(2)
  },
}));

const ProductsToolbar = props => {
  const { className, handleAddAll, handleSearch, onApiError, searching, ...rest } = props;

  const classes = useStyles();

  const [lines, setLines] = useState([]);
  useEffect(() => {
    apiLine().then(response => {
      if (response.status === 200 && response.data.status)
        setLines(response.data.data);
      else
        onApiError(response);
    });
  }, []);
  
  const [machines, setMachines] = useState([]);
  const [parts, setParts] = useState([]);
    
  const [lineId, setLineId] = useState("");
  const [machineId, setMachineId] = useState("");
  const [partId, setPartId] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");

  const onLineChange = (value) => {
    setLineId(value);
    setMachineId("");
    setPartId("");
    setMachines([]);
    setParts([]);
    apiMachine(value).then(response => {
      if (response.status === 200 && response.data.status)
        setMachines(response.data.data);
      else
        onApiError(response);
    });
  }

  const onMachineChange = (value) => {
    setMachineId(value);
    setPartId("");
    setParts([]);
    apiPart(lineId, value).then(response => {
      if (response.status === 200 && response.data.status)
        setParts(response.data.data);
      else
        onApiError(response);
    });
  }

  const onPartChange = (value) => {
    setPartId(value);
  }

  const onCodeChange = (value) => {
    setCode(value);
  }

  const onDescriptionChange = (value) => {
    setDescription(value);
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
      <AssignmentTurnedIn />
      <Typography className={classes.title}
        variant="h4"
      >
        {"Acuerdos de venta"}
      </Typography>
      </div>
      {props.cart ?
      <Fragment>
      <Grid container>
        <Grid item md={2} xs={12}>
          <TextField select variant="outlined" value={lineId} label={"Línea"} className={classes.formControl} onChange={(e) => onLineChange(e.target.value)}>
            {lines.map((item) => (
                <MenuItem key={"line_" + item.CatalogoLinea} value={item.CatalogoLinea}>
                  {item.CatalogoLinea}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item md={2} xs={12}>
        <TextField select variant="outlined" value={machineId} label={"Máquina"} className={classes.formControl} onChange={(e) => onMachineChange(e.target.value)}>
          {machines.map((item) => (
              <MenuItem key={"machine_" + item.CatalogoMaquina} value={item.CatalogoMaquina}>
                {item.CatalogoMaquina}
              </MenuItem>
            ))}
        </TextField>
        </Grid>
        <Grid item md={2} xs={12}>
        <TextField select variant="outlined" value={partId} label={"Parte"} className={classes.formControl} onChange={(e) => onPartChange(e.target.value)}>
          {parts.map((item) => (
              <MenuItem key={"part_" + item.CatalogoParte} value={item.CatalogoParte}>
                {item.CatalogoParte}
              </MenuItem>
            ))}
        </TextField>
        </Grid>
        <Grid item md={2} xs={12}>
          <TextField variant="outlined" value={code} label="Código" className={classes.formControl} onChange={(e) => onCodeChange(e.target.value)} />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField variant="outlined" value={description} label="Descripción" className={classes.formControl} onChange={(e) => onDescriptionChange(e.target.value)} />
        </Grid>
      </Grid>
      <Grid className={classes.searchRow}>
        {searching ? <CircularProgress size={30} className={classes.progress} /> :
        <Button
          color="primary"
          variant="contained"
          className={classes.searchButton}
          onClick={() => handleSearch(lineId, machineId, partId, code, description)}
        >
          <Hidden smUp><SearchIcon /></Hidden>
          <Hidden xsDown>Buscar</Hidden>
        </Button>}
        <Button
          color="secondary"
          variant="contained"
          onClick={() => handleAddAll()}
        >
          <Hidden smUp><AddIcon /></Hidden>
          <Hidden xsDown>{getDictionary(props.cart.type, 'add_action')}</Hidden>
        </Button>
      </Grid>
      </Fragment>
      : null}

    </div>
  );
};

ProductsToolbar.propTypes = {
  className: PropTypes.string
};

export default ProductsToolbar;
