import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Card, CardContent, Typography } from '@material-ui/core';
import { OrderToolbar, OrderTable } from './components';
import { NewCartDialog, Snackbar, SuccessSnackbar, TableProgress } from '../../components';
import { create as createCart, remove as removeCart } from '../../actions/cart';
import { logout } from '../../actions/authentication';
import { TYPE_ORDER } from '../../constants/cart';
import history from '../../helpers/history';
import { MODE_LIST } from '../../constants/order';
import { search as apiSearch } from '../../api/order';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
}));

const OrderList = (props) => {
  const classes = useStyles();

  const [message, setMessage] = useState({ open: false, autoHideDuration: null, severity: "success", title: "", messages: [] });
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [openNewOrderConfirm, setOpenNewOrderConfirm] = useState(false);
  const [searching, setSearching] = useState(false);

  const newOrderConfirm = (confirm) => {
    setOpenNewOrderConfirm(false);
    if (confirm)
      setNewOrder();
  }

  const onNewOrder = () => {
    if (props.cart)
      setOpenNewOrderConfirm(true);
    else
      setNewOrder();
  }

  const setNewOrder = () => {
    props.createCart(TYPE_ORDER);
    history.push('/products');
  }

  const onViewOrder = (id) => {
    history.push('/order/' + id);
  }

  // Search
  const handleSearch = (year, month) => {
    setSearching(true);
    apiSearch(year, month).then(response => {
      if (response.status === 200 && response.data.status) {
        // Map offers
        setPage(0);
        setOrders(response.data.data);
      }
      else {
        onApiError(response);
      }
      setSearching(false);
    });
  }
  
  const onApiError = (response) => {
    if (response.status === 401) {
      props.logout();
    }
    else {
      setMessage({
        open: true,
        autoHideDuration: null,
        severity: "error",
        title: "Error",
        messages: ["Ha ocurrido un error. Por favor inténtelo de nuevo."] });
    }
  }

  const handleClose = () => {
    setMessage({ ...message, open: false });
  };

  return (
    <div className={classes.root}>
      <OrderToolbar title={"Pedidos"} mode={MODE_LIST} onNewOrder={() => onNewOrder()} onFiltersChange={(year, month) => handleSearch(year, month)} />
      <div className={classes.content}>
        {searching ? <TableProgress /> :
          orders.length === 0 ?
          <Card>
            <CardContent>
              <Typography align="center">
                {"No se han encontrado resultados para su búsqueda."}
                </Typography>
              </CardContent>
          </Card>
        : 
        <OrderTable
          orders={orders}
          page={page}
          setPage={(page) => setPage(page)}
          onViewOrder={(id) => onViewOrder(id)}
        />}
      </div>
      <NewCartDialog
        open={openNewOrderConfirm}
        type={TYPE_ORDER}
        cart={props.cart}
        id={null}
        onConfirm={(confirm, id) => newOrderConfirm(confirm)}
      />
      <Snackbar
          open={message.open}
          autoHideDuration={message.autoHideDuration}
          severity={message.severity}
          title={message.title}
          messages={message.messages}
          handleClose={() => handleClose()}
        />
        <SuccessSnackbar />
    </div>
  );
};

const mapStateToProps = state => ({
  cart: state.cart
});

const mapDispatchToProps = dispatch => {
  return {
    createCart: (type) => dispatch(createCart(type)),
    logout: () => {
      dispatch(removeCart());
      dispatch(logout());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);