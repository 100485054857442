import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { OrderToolbar } from './components';
import { CartList, CartDetails, Snackbar, TableProgress } from '../../components';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { MODE_VIEW } from '../../constants/order';
import { remove as removeCart } from '../../actions/cart';
import { logout } from '../../actions/authentication';
import { getById as apiGetOrderById } from '../../api/order';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  grid: {
    marginTop: 1
  }
}));

const Order = (props) => {
    
    const classes = useStyles();

    const { id } = useParams();
    const [cart, setCart] = useState(null);
    const [details, setDetails] = useState(null);
    const [message, setMessage] = useState({ open: false, autoHideDuration: null, severity: "success", title: "", messages: [] });

    const handleClose = () => {
        setMessage({ ...message, open: false });
    };

    const onApiError = (response) => {
        if (response.status === 401) {
        props.logout();
        }
        else {
        setMessage({
            open: true,
            autoHideDuration: null,
            severity: "error",
            title: "Error",
            messages: response.data.data ? response.data.data : [response.data.message] });
        }
    }

    useEffect(() => {
        apiGetOrderById(id).then(response => {
          if (response.status === 200 && response.data.status)
            setCart(response.data.data);
          else
            onApiError(response);
        });
    }, []);
    
    const onDetailsChange = (values) => {
        setDetails(values);
    }

    return (
    <div className={classes.root}>
        {cart ?
            <Fragment>
                <OrderToolbar
                    title={"Pedido # " + cart.reference}
                    mode={MODE_VIEW}
                    onNewOffer={() => { }}
                    onOfferToOrder={() => { }}
                    onFiltersChange={() => { }}
                />
                <Grid container spacing={4} className={classes.grid} >
                    <Grid item lg={4} md={6} xl={4} xs={12}>
                        <CartDetails
                        type={cart.type}
                        cart={cart}
                        title={"Datos del pedido"}
                        disabled={true}
                        showReference={false}
                        back={"/orders"}
                        onApiError={(response) => onApiError(response)}
                        onValuesChange={(values) => onDetailsChange(values)}
                        />
                    </Grid>
                    <Grid item lg={8} md={6} xl={8} xs={12}>
                        <CartList cart={cart} details={details} calculateTotals={false} />
                    </Grid>
                </Grid>
            </Fragment>
        : <TableProgress />}
        <Snackbar
          open={message.open}
          autoHideDuration={message.autoHideDuration}
          severity={message.severity}
          title={message.title}
          messages={message.messages}
          handleClose={() => handleClose()}
        />
    </div>
    );
};

const mapStateToProps = state => ({
    cart: state.cart
});

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(removeCart());
            dispatch(logout());
          },
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(Order);