import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Hidden
} from '@material-ui/core';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { deepOrange, deepPurple, green } from '@material-ui/core/colors';
import { SortingColumn } from '../../../../components';
import { toCurrency, toDate } from '../../../../helpers/format';
import { getStatusName } from '../../../../helpers/status';

const useStyles = makeStyles(theme => ({
  root: { },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  actions: {
    justifyContent: 'flex-end'
  },
  view: {
      width: 140
  },
  A: {
    color: '#fff',
    backgroundColor: green[500],
  },
  C: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  F: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  }
}));

const DeliveryTable = props => {
  const { className, deliveries, page, setPage, onViewDelivery, ...rest } = props;

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [sort, setSort] = useState('reference');
  const [sortDirection, setSortDirection] = useState('desc');
  
  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };
  const onSorting = (newSort, newSortDirection) => {
    setSort(newSort);
    setSortDirection(newSortDirection);
  };

  const sortedDeliveries = () => {
    return deliveries.sort((a, b) => {
      const aValue = sort.split('.').reduce((c, d) => c[d], a);
      const bValue = sort.split('.').reduce((c, d) => c[d], b);
      if (aValue > bValue) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      if (aValue < bValue) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      return 0;
    });
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                <SortingColumn sort={"reference"} label={"# Referencia"} currentSort={sort} currentSortDirection={sortDirection} onSorting={(newSort, newSortDirection) => onSorting(newSort, newSortDirection)} />
                  <SortingColumn sort={"date"} label={"Fecha"} currentSort={sort} currentSortDirection={sortDirection} onSorting={(newSort, newSortDirection) => onSorting(newSort, newSortDirection)} />
                  <SortingColumn sort={"code"} label={"Código"} currentSort={sort} currentSortDirection={sortDirection} onSorting={(newSort, newSortDirection) => onSorting(newSort, newSortDirection)} />
                  <SortingColumn sort={"description"} label={"Descripción"} currentSort={sort} currentSortDirection={sortDirection} onSorting={(newSort, newSortDirection) => onSorting(newSort, newSortDirection)} />
                  <SortingColumn sort={"price"} label={"Precio"} currentSort={sort} currentSortDirection={sortDirection} onSorting={(newSort, newSortDirection) => onSorting(newSort, newSortDirection)} />
                  <SortingColumn sort={"qty"} label={"Cantidad"} currentSort={sort} currentSortDirection={sortDirection} onSorting={(newSort, newSortDirection) => onSorting(newSort, newSortDirection)} />
                  <SortingColumn sort={"unit"} label={"UDM"} currentSort={sort} currentSortDirection={sortDirection} onSorting={(newSort, newSortDirection) => onSorting(newSort, newSortDirection)} />
                  <SortingColumn sort={"status"} label={"Estado"} currentSort={sort} currentSortDirection={sortDirection} onSorting={(newSort, newSortDirection) => onSorting(newSort, newSortDirection)} />
                  <TableCell className={classes.view}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedDeliveries().slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map(delivery => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={delivery.id}
                  >
                    <TableCell>
                        {delivery.reference}
                    </TableCell>
                    <TableCell>{toDate(delivery.date)}</TableCell>
                    <TableCell>
                        {delivery.code}
                    </TableCell>
                    <TableCell>{delivery.description}</TableCell>
                    <TableCell>
                      {toCurrency(delivery.price)}
                    </TableCell>
                    <TableCell>{delivery.qty}</TableCell>
                    <TableCell>{delivery.unit}</TableCell>
                    <TableCell>
                      <Tooltip title={getStatusName(delivery.status)} placement="top-start">
                        <Avatar className={classes[delivery.status]}>{delivery.status}</Avatar>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                    <Button color="primary" variant="contained" onClick={() => onViewDelivery(delivery.delivery_id)}>
                      <Hidden smUp><RemoveRedEyeIcon /></Hidden>
                      <Hidden xsDown>Ver</Hidden>
                    </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={deliveries.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </CardActions>
    </Card>
  );
};

DeliveryTable.propTypes = {
  className: PropTypes.string,
  deliveries: PropTypes.array.isRequired
};

export default DeliveryTable;
