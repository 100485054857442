import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles(theme => ({
  root: { },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  },
  title: {
    marginLeft: 10
  }
}));

const HomeToolbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
      <HomeIcon />
      <Typography className={classes.title}
        variant="h4"
      >
        Home
      </Typography>
      </div>
    </div>
  );
};

HomeToolbar.propTypes = {
  className: PropTypes.string
};

export default HomeToolbar;
