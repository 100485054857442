import React from 'react';
import { Card, CardContent, Typography, CircularProgress } from '@material-ui/core';

const TableProgress = () => {
  return (
    <Card>
        <CardContent>
            <Typography align="center">
                <CircularProgress />
            </Typography>
            </CardContent>
        </Card>
  );
};

export default TableProgress;
