import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Hidden
} from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toCurrency, toDate } from '../../helpers/format';
import { getCartTotal } from '../../helpers/cart';
import { TYPE_OFFER } from 'constants/cart';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  price: {
      width: 100
  },
  qty: {
      width: 100
  },
  unit: {
    width: 180
  },
  addToCart: {
      width: 150
  }
}));

const CartTable = props => {
  const { className, cart, updateItem, deleteItem, ...rest } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleQtyChange = (e, item) => {
    const qty = parseInt(e.target.value);
    item.qty = isNaN(qty) ? 0 : qty;
    updateItem(item);
  };
  
  const handleDeleteOpen = (item) => {
    setItemToDelete(item);
    setOpen(true);
  };

  const handleDeleteClose = (mustDelete) => {
    if (mustDelete)
      deleteItem(itemToDelete);
    setOpen(false);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell>{cart.type === TYPE_OFFER ? "Plazo de entrega" : "Fecha de entrega"}</TableCell>
                  <TableCell className={classes.price}>Precio</TableCell>
                  <TableCell className={classes.qty}>Cantidad</TableCell>
                  <TableCell className={classes.unit}>UDM</TableCell>
                  <TableCell className={classes.price}>Total</TableCell>
                  <TableCell className={classes.addToCart}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cart.items.map(item => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={item.id}
                  >
                    <TableCell>
                        {item.code}
                    </TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>
                      {cart.type === TYPE_OFFER ? item.term : toDate(item.delivery)}
                    </TableCell>
                    <TableCell>
                      {toCurrency(item.unit.price)}
                    </TableCell>
                    <TableCell>
                        <TextField variant="outlined" value={item.qty === 0 ? '' : item.qty} onChange={(e) => handleQtyChange(e, item)} />
                    </TableCell>
                    <TableCell>
                      {item.unit.name}
                    </TableCell>
                    <TableCell>
                      {toCurrency(item.qty * item.unit.price)}
                    </TableCell>
                    <TableCell>
                    <Button color="default" variant="contained" onClick={() => handleDeleteOpen(item)}>
                      <Hidden smUp><ClearIcon /></Hidden>
                      <Hidden xsDown>Eliminar</Hidden>
                    </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableHead>
              <TableRow>
                  <TableCell>Total</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell className={classes.price}></TableCell>
                  <TableCell className={classes.qty}></TableCell>
                  <TableCell className={classes.unit}></TableCell>
                  <TableCell className={classes.price}>{toCurrency(getCartTotal(cart))}</TableCell>
                  <TableCell className={classes.addToCart}></TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Dialog
              open={open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Eliminar producto"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  ¿Está seguro que desea eliminar este producto?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleDeleteClose(true)} color="primary" autoFocus>
                  Sí
                </Button>
                <Button onClick={() => handleDeleteClose(false)} color="primary">
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

CartTable.propTypes = {
  className: PropTypes.string,
  cart: PropTypes.object.isRequired,
  deleteItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
};

export default CartTable;
