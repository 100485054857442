import moment from 'moment';

export const toCurrency = (number, currency = 'USD') => {
    return new Intl.NumberFormat('es-AR', { 
        style: 'currency', 
        currency: currency,
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2 
      }).format(number);
}

export const toDate = (date) => {
  if (date)
    return moment(date).format("DD/MM/YYYY");
  return '';
}