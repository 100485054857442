import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Typography, Hidden, Grid } from '@material-ui/core';
import {
  AirportShuttle as AirportShuttleIcon,
  KeyboardBackspace as KeyboardBackspaceIcon,
} from '@material-ui/icons';
import history from '../../../../helpers/history';
import { MonthFilter, YearFilter } from '../../../../components';
import { MODE_LIST, MODE_VIEW } from '../../../../constants/delivery';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    marginTop: theme.spacing(2)
  },
  innerRow: {
    display: 'flex'
  },
  buttonRow: {
    textAlign: 'right',
    paddingTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  title: {
    marginLeft: 10
  }
}));

const DeliveryToolbar = props => {
  const { className, mode, title, onFiltersChange, ...rest } = props;

  const classes = useStyles();

  const [year, setYear] = useState(parseInt(moment().format("YYYY")));
  const [month, setMonth] = useState(moment().format("MM"));

  const setYearFilter = (year) => {
    setYear(year);
  }

  const setMonthFilter = (month) => {
    setMonth(month);
  }

  useEffect(() => {
    onFiltersChange(year, month);
  }, [year, month]);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
      <Grid container>
      <Grid item className={classes.innerRow} md={6} xs={12}>
      <AirportShuttleIcon />
      <Typography className={classes.title}
        variant="h4"
      >
        {title}
      </Typography>
      </Grid>
      <Grid item className={classes.buttonRow} md={6} xs={12}>
      {mode === MODE_VIEW ?
        <Button
        color="default"
        variant="contained"
        onClick={() => history.push("/deliveries")}
        >
          <Hidden smUp><KeyboardBackspaceIcon /></Hidden>
          <Hidden xsDown>Volver</Hidden>
        </Button>
      : null}
      </Grid>
      </Grid>
      </div>
      {mode === MODE_LIST ?
      <div className={classes.row}>
        <MonthFilter month={month} setMonth={(month) => setMonthFilter(month)} />
        <YearFilter year={year} setYear={(year) => setYearFilter(year)} />
      </div> : null}
    </div>
  );
};

DeliveryToolbar.propTypes = {
  className: PropTypes.string
};

export default DeliveryToolbar;
