import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Tooltip,
  MenuItem,
  Hidden
} from '@material-ui/core';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import AddIcon from '@material-ui/icons/Add';
import { getDictionary } from '../../../../helpers/cart';
import { toCurrency, toDate } from '../../../../helpers/format';
import { SortingColumn, TableProgress } from '../../../../components';
import { unit as apiUnit } from '../../../../api/pruduct';
import { TYPE_OFFER } from 'constants/cart';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  actions: {
    justifyContent: 'flex-end'
  },
  price: {
      width: 100
  },
  qty: {
      width: 100
  },
  unit: {
    width: 180
  },
  onSale: {
    marginLeft: "10px"
  }
}));

const ProductsTable = props => {
  const { className, page, setPage, onApiError } = props;

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [sort, setSort] = useState('description');
  const [sortDirection, setSortDirection] = useState('asc');
  const [searching, setSearching] = useState(false);

  const handleSelectAll = event => {
    
    let selectedProducts = [...props.selectedProducts];

    const productsWithUnit = sortedProductsOnCurrentPageWithUnit();
    if (event.target.checked) {
      productsWithUnit.forEach(productWithoutUnit => {
        if (selectedProducts.indexOf(productWithoutUnit.id) === -1)
          selectedProducts.push(productWithoutUnit.id);
      });
    } else {
      productsWithUnit.forEach(productWithoutUnit => {
        selectedProducts = selectedProducts.filter(id => id !== productWithoutUnit.id);
      });
    }

    props.handleSelectProducts(selectedProducts);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = props.selectedProducts.indexOf(id);
    let newSelectedProducts = [];

    if (selectedIndex === -1) {
      newSelectedProducts = newSelectedProducts.concat(props.selectedProducts, id);
    } else if (selectedIndex === 0) {
      newSelectedProducts = newSelectedProducts.concat(props.selectedProducts.slice(1));
    } else if (selectedIndex === props.selectedProducts.length - 1) {
      newSelectedProducts = newSelectedProducts.concat(props.selectedProducts.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedProducts = newSelectedProducts.concat(
        props.selectedProducts.slice(0, selectedIndex),
        props.selectedProducts.slice(selectedIndex + 1)
      );
    }

    props.handleSelectProducts(newSelectedProducts);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const actionName = getDictionary(props.cart.type, 'add_action');

  const handleQtyChange = (e, product) => {
    const qty = parseInt(e.target.value);
    product.qty = isNaN(qty) ? 0 : qty;
    props.updateProducts(props.products);
  };

  const handleUnitChange = (e, product) => {
    product.unit = product.units.find(unit => {
      return unit.id === e.target.value
    });
    props.updateProducts(props.products);
  };

  const onSorting = (newSort, newSortDirection) => {
    setSort(newSort);
    setSortDirection(newSortDirection);
  };

  const sortProducts = () => {
    return props.products.sort((a, b) => {
      const aValue = sort.split('.').reduce((c, d) => c ? c[d] : null, a);
      const bValue = sort.split('.').reduce((c, d) => c ? c[d] : null, b);
      if (aValue > bValue) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      if (aValue < bValue) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      return 0;
    });
  };

  const sortedProductsOnCurrentPageWithUnit = () => {
    const sortedProducts = sortProducts().slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage);
    return sortedProducts.filter(product => { return product.unit && !product.not_available; });
  };

  const sortedProducts = () => {
    
    const sortedProducts = sortProducts().slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage);
    const productsWithoutUnit = sortedProducts.filter(product => { return !product.unit && !product.not_available; }).map(productWithoutUnit => { return productWithoutUnit.id; });
    
    if (productsWithoutUnit.length > 0) {
      
      setSearching(true);
      
      apiUnit(productsWithoutUnit).then(response => {
        
        if (response.status === 200 && response.data.status) {
          
          response.data.data.forEach(productWithUnit => {
            
            let product = props.products.find(product => { return product.id === productWithUnit.id; });
            product.not_available = productWithUnit.not_available;
            product.on_sale = productWithUnit.on_sale;
            
            if (!product.not_available) {
              product.delivery = productWithUnit.delivery;
              product.term = productWithUnit.term;
              product.unit = productWithUnit.unit;
              product.units = productWithUnit.units;
              // Append obs
              product.obs = productWithUnit.obs;
            }

            props.updateProducts(props.products);
          
          });
          
        }
        else {
          // Set error on products
          productsWithoutUnit.forEach(id => {
            let product = props.products.find(product => { return product.id === id; });
            product.not_available = true;
          });
          props.updateProducts(props.products);
          onApiError(response);
        }

        setSearching(false);
          
      });
    }

    return sortedProducts;
  };

  const areAllSortedProductsOnCurrentPageWithUnitSelected = () => {
    const productsWithUnit = sortedProductsOnCurrentPageWithUnit();
    
    if (productsWithUnit.length === 0 || props.selectedProducts.length === 0)
      return false;

    let found = true;
    productsWithUnit.forEach(productWithUnit => {
      if (props.selectedProducts.indexOf(productWithUnit.id) === -1)
        found = false;
    });

    return found;
  };

  const areSomeSortedProductsOnCurrentPageWithUnitSelected = () => {
    const productsWithUnit = sortedProductsOnCurrentPageWithUnit();
    
    if (productsWithUnit.length === 0 || props.selectedProducts.length === 0)
      return false;
    
    let count = 0;
    productsWithUnit.forEach(productWithUnit => {
      if (props.selectedProducts.indexOf(productWithUnit.id) !== -1)
        count++;
    });
    
    return count > 0 && count < productsWithUnit.length;
  };

  if (searching)
    return <TableProgress />;

  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={areAllSortedProductsOnCurrentPageWithUnitSelected()}
                      color="primary"
                      indeterminate={areSomeSortedProductsOnCurrentPageWithUnitSelected()}
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <SortingColumn sort={"code"} label={"Código"} currentSort={sort} currentSortDirection={sortDirection} onSorting={(newSort, newSortDirection) => onSorting(newSort, newSortDirection)} />
                  <SortingColumn sort={"description"} label={"Descripción"} currentSort={sort} currentSortDirection={sortDirection} onSorting={(newSort, newSortDirection) => onSorting(newSort, newSortDirection)} />
                  <SortingColumn sort={props.cart.type === TYPE_OFFER ? "term" : "delivery"} label={props.cart.type === TYPE_OFFER ? "Plazo de entrega" : "Fecha de entrega"} currentSort={sort} currentSortDirection={sortDirection} onSorting={(newSort, newSortDirection) => onSorting(newSort, newSortDirection)} />
                  <SortingColumn sort={"unit.price"} label={"Precio"} currentSort={sort} currentSortDirection={sortDirection} onSorting={(newSort, newSortDirection) => onSorting(newSort, newSortDirection)} />
                  <TableCell className={classes.qty}>Cantidad</TableCell>
                  <TableCell className={classes.unit}>UDM</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedProducts().map(product => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={product.id}
                    selected={props.selectedProducts.indexOf(product.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      {product.unit && <Checkbox
                        checked={props.selectedProducts.indexOf(product.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, product.id)}
                        value="true"
                      />}
                    </TableCell>
                    <TableCell>
                        {product.code}
                    </TableCell>
                    <TableCell>{product.description}</TableCell>
                    <TableCell>
                      {props.cart.type === TYPE_OFFER ? product.term : toDate(product.delivery)}
                    </TableCell>
                    <TableCell>
                      {(product.unit ? toCurrency(product.unit.price) : "")} {(product.on_sale ? <Tooltip title={"Oferta"}><CardGiftcardIcon className={classes.onSale} /></Tooltip> : null)}
                    </TableCell>
                    <TableCell>
                      {product.unit && <TextField variant="outlined" value={product.qty === 0 ? '' : product.qty} onChange={(e) => handleQtyChange(e, product)} />}
                    </TableCell>
                    <TableCell>
                      {product.not_available ? "No disponible" : (product.unit &&
                      <TextField select variant="outlined" value={product.unit.id} onChange={(e) => handleUnitChange(e, product)}>
                        {product.units.map((unit) => (
                            <MenuItem key={unit.id} value={unit.id}>
                              {unit.name}
                            </MenuItem>
                          ))}
                      </TextField>)}
                    </TableCell>
                    <TableCell>
                    {product.unit && <Button color="secondary" variant="contained" onClick={() => props.handleAddProduct(product.id)}>
                      <Hidden smUp><AddIcon /></Hidden>
                      <Hidden xsDown>{actionName}</Hidden>
                    </Button>}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={props.products.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </CardActions>
    </Card>
  );
};

ProductsTable.propTypes = {
  className: PropTypes.string,
  products: PropTypes.array.isRequired
};

export default ProductsTable;
