import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1)
  },
}));

const CartEmpty = (props) => {
  const classes = useStyles();
  return (
    <Card><CardContent><Typography className={classes.root} align="center">{props.message}</Typography></CardContent></Card>    
  );
};

export default CartEmpty;
