import config from '../config/api.json';
import { STORAGE_USER } from '../constants/authentication';
const axios = require('axios');

const instance = axios.create({
    baseURL: config.apiUrl,
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    validateStatus: function (status) {
        return (status >= 200 && status < 300) || status === 401;
    },
});

const makeResponse = (status, data) => {
    return { status: status, data: data };
}

const makeRequest = async (url, method, data, mustAuthorize, mustRefreshToken = true) => {

    let user = null;
    if (mustAuthorize) {
        const storageUser = localStorage.getItem(STORAGE_USER);
        user = storageUser ? JSON.parse(storageUser) : null;
        if (!user)
            return makeResponse(401, "No se encuentra autorizado para realizar esta operación.");
        instance.defaults.headers.common["Authorization"] = "Bearer " + user.token;
    }
    
    const request = {
      url: url,
      method: method === "download" ? "get" : method,
      data: data
    };
    if (method === "download")
      request.responseType = 'blob';

    return instance.request(request)
    .then((response) => {
        if (response.status === 401) {
            // Try refresh token
            if (mustAuthorize && mustRefreshToken) {
                const refreshToken = {
                    'grant_type': 'refresh_token',
                    'refresh_token': user.refreshToken,
                    'client_id': config.clientId,
                    'client_secret': config.clientSecret,
                };
                return makeRequest('oauth/token', 'post', refreshToken, mustAuthorize, false)
                    .then((response) => {
                        if (response.status === 200) {
                            // Store access tokens
                            user.token = response.data.access_token;
                            user.refreshToken = response.data.refresh_token;
                            localStorage.setItem(STORAGE_USER, JSON.stringify(user));
                            // Make original request again
                            return makeRequest(url, method, data, mustAuthorize);
                        }
                        else {
                            return response;
                        }
                    });
            }
            else {
                // Return 401
                return makeResponse(response.status, "No se encuentra autorizado para realizar esta operación.");
            }
        }
        else {
            return makeResponse(response.status, response.data);
        }
    })
    .catch((error) => {
        console.log(error);
        return makeResponse(error.response ? error.response.status : 400, "Ha ocurrido un error. Por favor inténtelo de nuevo.");
    });
}

export const get = (url, mustAuthorize = true) => {
    return makeRequest(url, 'get', null, mustAuthorize);
}

export const post = (url, data, mustAuthorize = true) => {
    return makeRequest(url, 'post', data, mustAuthorize);
}

export const download = (url, mustAuthorize = true) => {
  return makeRequest(url, 'download', null, mustAuthorize);
}
