import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import InputIcon from '@material-ui/icons/Input';
import { connect } from 'react-redux';
import { logout } from '../../../../actions/authentication';
import { remove as removeCart } from '../../../../actions/cart';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  topbarText: {
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: 18
  },
  link: {
    color: "#ffffff"
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, logout, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <Typography className={classes.topbarText}>Portal Uffenheimer</Typography>
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden smUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        {props.cart && <IconButton color="inherit">
          <RouterLink to="/cart" className={classes.link}>
            <Badge badgeContent={props.cart.items.length} color="error">
              <ShoppingCartIcon />
            </Badge>
            </RouterLink>
          </IconButton>}
          
          <IconButton
            onClick={() => logout()}
            className={classes.signOutButton}
            color="inherit"
          >
            <InputIcon />
          </IconButton>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

const mapStateToProps = state => ({
  cart: state.cart
});

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(removeCart());
      dispatch(logout());
    }
}}

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
